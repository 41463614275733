import React, {useState, useEffect, useContext} from "react";
import {Button} from "./components/buttons";
import Calendar from "react-calendar";
import {Msg, PopupScreen} from "./page-utils";
import Head from "./page-sections/Head";
import { useTrail, a } from 'react-spring'
import IgAnim from "./page-utils/IgAnim";
import FormBox from "./page-utils/FormBox";
import Label from "./page-utils/Label";
import Body, {BodyTemplates} from "./page-sections/Body";
import useFetchApi, {RequestStatusEnum} from "../custom-hooks/useFetchApi";
import {useParams} from "react-router-dom";
import helpers from "../helpers";
import AppContext from "../context/AppContext";






function ScheduleNew() {


    const {state: AppContextState, dispatch: AppContextDispatch} = useContext(AppContext);

    const { rateId, rateIdHash } = useParams();



    const [requestData, setRequestData] = useState({'rate_id':rateId, 'rate_id_hash':rateIdHash})
    const {requestStatus, responseData} = useFetchApi('get_ad_rate_for_schedule', requestData);




    /*const [newAdRateFormData, setNewAdRateFormData] = useState({'rate_id':rateId, 'rate_id_hash':rateIdHash})*/
    const [newAdRateRequestData, setSubmitRequestData] = useState({}) /*'rate_id':rateId, 'rate_id_hash':rateIdHash*/
    const {requestStatus: submitRequestStatus, responseData: submitResponseData} = useFetchApi('new_ad_rate_for_schedule', newAdRateRequestData);


    const [currentStep, setCurrentStep] = useState(0);




    const currentTime = Math.floor((new Date().getTime()) / 1000);
    let curDateInit = new Date(currentTime * 1000);
    const [scheduleData, setScheduleData] = useState({
        minDate: curDateInit,
        maxDate: curDateInit,
        currentDate: curDateInit,
        minPostHour: 0,
        maxPostHour: 0,
        allowedScheduleTimestamps: [],
        selectedScheduleTimestamp: 0,
    })





    useEffect(()=>{
        if(responseData.success){



            let timestamps = helpers.get_between_hours_timestamps(responseData.data.allowed_post_min_hour_timetstamp, responseData.data.allowed_post_max_hour_timetstamp, scheduleData.currentDate.getTime());

            setScheduleData({
                ...scheduleData,
                currentDate: new Date(responseData.data.min_date_timestamp * 1000),
                minDate: new Date(responseData.data.min_date_timestamp * 1000),
                maxDate: new Date(responseData.data.max_date_timestamp * 1000),
                allowedScheduleTimestamps: timestamps
            })

        }


    }, [responseData])


    useEffect(()=>{
        if(responseData.success){
            let timestamps = helpers.get_between_hours_timestamps(responseData.data.allowed_post_min_hour_timetstamp, responseData.data.allowed_post_max_hour_timetstamp, scheduleData.currentDate.getTime());
            setScheduleData({
                ...scheduleData,
                allowedScheduleTimestamps: timestamps
            })

            if(currentStep>0){
                setCurrentStep(currentStep + 1)
            }
        }


    }, [scheduleData.currentDate])



    function FormNav(){
        return (<>
            <Label className='input-grid'>
                <Button type='btn-onboard' onClick={() => {
                    setCurrentStep(currentStep -1)
                }}>Go Back</Button>

                <Button type='btn-onboard' onClick={() => {
                    setCurrentStep(currentStep + 1)
                }}>Continue</Button>
            </Label>
        </>);
    }


    function onSelectScheduledTimeHandler(timestamp){
        //alert(timestamp);
        setScheduleData({...scheduleData, selectedScheduleTimestamp: timestamp})
        setCurrentStep(currentStep + 1);
    }

    //setScheduleData({...scheduleData, selectedScheduleTimestamp: allowed_timestamp})


    //function




    return (<>

        <Body template={BodyTemplates.MAIN}>

            <FormBox className='onboard textalign-center' border={true} title='Schedule Ad'>



                {requestStatus == RequestStatusEnum.LOADING &&
                <Label>
                    <Msg type='loading'>Loading...</Msg>
                </Label>
                }


                {requestStatus == RequestStatusEnum.DONE && <>

                    {currentStep == 0 && (<>

                        <Label>
                            <header>
                                <h4>
                                    {responseData.data.ad_length_in_hours} for ${responseData.data.price}
                                </h4>
                            </header>
                        </Label>

                        <Label>
                            <Button template='onboard' className='width100' onClick={() => {
                                setCurrentStep(1)
                            }}>Schedule To Post Ad</Button>
                        </Label>







                            <article >
                                Schedule ads based on previous interactions with <span className='color-white'>DickSender</span> and get paid automatically
                            </article>



                        {/*<Label>
                            <IgAnim/>
                        </Label>*/}


                        <Label>
                            <div className='az-preview-wrap'>
                                <img src={(AppContextState.API.url + responseData.data.ad_file_location)}/>
                            </div>
                            {/*{AppContextState.PUBLIC_PATH}{responseData.data.ad_file_location}*/}
                        </Label>


                    </>)}

                    {currentStep == 1 && (<>






                        <header>
                            <h2>Pick a day for the ad to run</h2>
                            <h3>(Between {scheduleData.minDate.toLocaleString('default', { month: 'long' })} {scheduleData.minDate.getDate()} and {scheduleData.maxDate.toLocaleString('default', { month: 'long' })} {scheduleData.maxDate.getDate()})</h3>
                        </header>

                        <Label>
                            <div className='schedule-new-calender'>
                                <Calendar
                                    onChange={(curDate)=>{setScheduleData({...scheduleData, currentDate: curDate})}}
                                    value={scheduleData.currentDate}
                                    minDate={scheduleData.minDate}
                                    maxDate={scheduleData.maxDate}
                                />
                                <div className='clear'></div>
                            </div>
                        </Label>


                        <FormNav/>


                    </>)}


                    {currentStep == 2 && (<>


                        <Label>
                            <header>
                                <h2>Pick a time for the ad to run</h2>
                                <h3>(Must be between 11am-2pm)</h3>
                            </header>
                        </Label>

                        <Label>



                            <div className={'pick-a-time-wrap'}>

                                {


                                }

                                {scheduleData.allowedScheduleTimestamps.map((allowed_timestamp, index)=>{

                                    let cur_date = (new Date(allowed_timestamp));


                                    return (<>
                                        {/*<header>
                                            <h4> 12/5/21</h4>
                                        </header>*/}



                                        <div className={'pick-a-time-item'}  style={helpers.getRowColor(cur_date.getDate(), 'dates')} onClick={()=>{onSelectScheduledTimeHandler(allowed_timestamp)}}>
                                            {helpers.convert_24h_to_12h(cur_date.getHours())}<span className={'font12'}> - ({cur_date.toDateString()})</span>
                                        </div>

                                    </>)


                                })}

                            </div>

                        </Label>

                        <FormNav/>
                    </>)}




                    {currentStep == 3 && (<>


                        <Label>
                            <header>
                                <h2>Confirm Schedule Details</h2>
                                <h3> </h3>
                            </header>
                        </Label>

                        <Label>


                            {/*<div className={'form-box-details'}>
                                <span>Price:</span>
                                <span>$300</span>
                            </div>
*/}

                            <article>
                                <div className={'form-box-details'}>
                                    <span className={'form-box-detail form-box-detail-key mobile-font14'}>Post Date:</span>
                                    <span className={'form-box-detail form-box-detail-value mobile-font14'}>{(new Date(scheduleData.selectedScheduleTimestamp)).toDateString()}</span>
                                </div>


                                <div className={'form-box-details'}>
                                    <span className={'form-box-detail form-box-detail-key mobile-font14'}>Price:</span>
                                    <span className={'form-box-detail form-box-detail-value mobile-font14'}>${responseData.data.price}</span>
                                </div>


                                <div className={'form-box-details'}>
                                    <span className={'form-box-detail form-box-detail-key mobile-font14'}>Post Length:</span>
                                    <span className={'form-box-detail form-box-detail-value mobile-font14'}>{responseData.data.ad_length_in_hours}</span>
                                </div>


                                <div className={'form-box-details'}>
                                    <span className={'form-box-detail form-box-detail-key mobile-font14'}>Instagram:</span>
                                    <span className={'form-box-detail form-box-detail-value mobile-font14'}>@*****</span>
                                </div>

                                <div className={'form-box-details'}>
                                    <span className={'form-box-detail form-box-detail-key mobile-font14'}>Payment Method:</span>
                                    <span className={'form-box-detail form-box-detail-value mobile-font14'}>*****@***.com</span>
                                </div>


                            </article>



                        </Label>

                        <FormNav/>
                    </>)}

                </>}

            </FormBox>

            <div className={'clear'}></div>





        </Body>


    </>)
}

export default ScheduleNew;