import React, {useState} from 'react';
//import ReactDOM from 'react-dom';
import {loadStripe} from '@stripe/stripe-js';
import {
    PaymentElement,
    CardElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import {Button} from "../components/buttons";
import useFetchApi from "../../custom-hooks/useFetchApi";

const CheckoutForm = () => {


    const [stripePayRequestData, setStripePayRequestData] = useState(false)
    const {requestStatus: stripePayRequestStatus, responseData: stripePayResponseData} = useFetchApi('stripe_pay', stripePayRequestData);



    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (elements == null) {
            return;
        }

        const card = await elements.getElement(CardElement);
        const result = await stripe.createToken(card);
        if (result.error) {
            console.log(result.error.message);
        } else {


            setStripePayRequestData({'token':result.token.id, 'tokens':100} )
            //console.log(result.token.id);
            //console.log(result);
            //console.log(result.token);
        }




    };




    return (
        <form onSubmit={handleSubmit}>

            <CardElement />
            <Button className='btn-stripe-pay' type="submit" disabled={!stripe || !elements}>
                Pay
            </Button>
        </form>
    );
};

const stripePromise = loadStripe('pk_test_51H8wrALXrJvmc9KK0DCp0MnSMD8Rx55kMSA6YUklKVqZVvJH4puqnE7bIiVwJvHYKar8iszjbkxo4FTzKT4qdL1d00lhynJM16');


function StripeCheckout() {



    return (<>
        <Elements stripe={stripePromise}>
            <CheckoutForm />
        </Elements>
    </>);
}


export default StripeCheckout;