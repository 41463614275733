import React from "react";


function FormBox(props){

    const {border, padding, className, title, children, sideComponent, ...otherProps} = props;


    return (<>
        <div  className={`form-box ${className ? className : ''} ${sideComponent===true ? 'side-component-box' : ''}`} {...otherProps}>
            {title && (
                <header>
                    <h1>{title}</h1>
                </header>

            )}

            <div className={'form-box-content' + (border===false ? ' border-none' : '' ) + (padding===false ? ' padding-none' : '' ) }>
                {children}
            </div>

            <div className='clear'></div>
        </div>
    </>);
}


export default FormBox;