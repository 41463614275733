import React, {useState, useEffect} from 'react';

function CheckBox({on, onCallBack, offCallBack, title, ...props}) {

    const [isOn, setIsOn] = useState(on || false)



    useEffect(function(){
        if(isOn){
            if(typeof onCallBack === 'function'){
                onCallBack();
            }
        } else {
            if(typeof offCallBack === 'function'){
                offCallBack();
            }
        }

    }, [isOn])


    return (<>

        <div className={`checkbox ${isOn ? '' : 'off'}`} onClick={()=>{setIsOn(!isOn)}} {...props}>
            <div className="desc">
                <span className="title mobile-font12">{title}</span>
                <span className="more">{props.children}</span>
            </div>
            <div className="action">
                <div className="slider-wrap">
                    <div className="slider">
                        <div className="toggle on">ON</div>
                        <div className="split">
                            <div className="diag-left"></div>
                            <div className="diag-right"></div>
                        </div>
                        <div className="toggle off">OFF</div>
                    </div>
                </div>
            </div>
        </div>


    </>);
}


export default CheckBox;