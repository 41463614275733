import React, {useState, useReducer, useEffect, useContext} from 'react';
import useFetchApi, {RequestStatusEnum} from "../../../custom-hooks/useFetchApi";
import Body, {BodyTemplates} from "../Body";
import FormBox from "../../page-utils/FormBox";
import {Msg} from "../../page-utils";
import Label from "../../page-utils/Label";
import {Button} from "../../components/buttons";
import {TextBox} from "../../components/TextBoxes";
import ApiMsg from "../../page-utils/ApiMsg";
import usePaginator from "../../../custom-hooks/usePaginator";
import PaginatorNavigator from "../../page-utils/PaginatorNavigator";
import {Link, useRouteMatch} from "react-router-dom";
import SideComponent from "../SideComponent";
import AppContext from "../../../context/AppContext";
import BodyContext from "../../../context/BodyContext";
import SideComponentBox from "../../page-utils/SideComponentBox";
import {FlexibleXYPlot, VerticalBarSeries, XYPlot} from "react-vis";



/*const defaultState = {
    step: 0
}


function reducer(state, action){
    switch (action.type){
        case 'SET_STEP':
            return {...state, step: {...action.payload}}
            break
    }
    throw new Error('no matching action type');
}*/



function ShowAzGroups({step, setStep, ...props}){
    /*const [reducerState, dispatch] = useReducer(reducer, defaultState);*/
    const { url, path } = useRouteMatch();


    const {requestStatus, responseData, page, setPage, totalPages, rowColors} = usePaginator('get_ad_groups', 0);





    return (<>
        <Msg type='info'>
            Create and manage a collection of ads
        </Msg>

        <Label>
            {requestStatus == RequestStatusEnum.LOADING &&
                <Label>
                    <Msg type='loading'>Loading...</Msg>
                </Label>
            }



            {requestStatus == RequestStatusEnum.DONE &&


                (responseData.data.rows.length>0 ?


                        (<>
                            {responseData.data.rows.map((item, i)=>{

                                return (
                                    <Link to={`${url}/${item.id}`} key={item.id}>
                                        <Label key={item.id} style={rowColors[i]} className='width100 rows rows-groups-grid'>
                                            <div className='row-title'>
                                                <span className={`variants-count pull-right ${item.variants_count!=='0' ? 'highlighted' : ''}`}>
                                                    {item.variants_count} Ads
                                                </span>

                                                {item.group_name}


                                            </div>
                                            <div className='row-actions'>

                                            </div>

                                        </Label>
                                    </Link>



                                )

                            })}


                            <PaginatorNavigator page={page} setPage={setPage} totalPages={totalPages}/>
                        </>)

                    :

                    <article>
                        No ad groups created yet..
                    </article>

                )

            }





        </Label>
        <Label >
            {/*<Button template='default' icon='plus' onClick={()=>{dispatch({type: 'SET_STEP', payload:reducerState.step + 1})}}>Create Group</Button>*/}
            <Button template='default' icon='plus' onClick={()=>{setStep(step+1)}}>Create Group</Button>
        </Label>
    </>);
};


function CreateAzGroup({step, setStep, ...props}){


    const [requestData, setRequestData] = useState({})
    const {requestStatus, responseData} = useFetchApi('new_ad_group', requestData);


    const [formObj, setFormObj] = useState({group_name:''});


    function onChange(e){
        setFormObj({...formObj, [e.target.name]:e.target.value });

    }

    function onSubmit(){
        setRequestData(formObj);
    }

    useEffect(function(){

        if(responseData.success){
            setStep(0);
        }
    }, [responseData]);

    return (<>
        <Msg type='info'>
            Enter the name of this group of ads...
        </Msg>

        <Label>
            <TextBox className='width100' placeholder='Name of group' name='group_name' onChange={onChange} value={formObj.group_name}/>
        </Label>
        <Label className='input-grid'>
            <Button template='default' onClick={onSubmit} icon='plus'>Save New Group</Button>
            <Button template='default' onClick={()=>{setStep(step-1)}}>Cancel</Button>
        </Label>

        <ApiMsg requestStatus={requestStatus} responseData={responseData}/>
    </>);
};



function AzGroups() {
    const [step, setStep] = useState(0);


    //const {bodyState, bodyDispatch} = useContext(BodyContext);

    useEffect(function(){


        //bodyDispatch({type:'SET_SIDE_COMPONENT', payload:'test me'})
    }, [])

    //dispatch({type:'SHOW_POPUPSCREEN', payload:<>Test MEEEEE</>})

    const data = [
        {x: 0, y: 8},
        {x: 1, y: 5},
        {x: 2, y: 4},
        {x: 3, y: 9},
        {x: 4, y: 1},
        {x: 5, y: 7},
        {x: 6, y: 6},
        {x: 7, y: 3},
        {x: 8, y: 2},
        {x: 9, y: 7},
        {x: 10, y: 8},
        {x: 11, y: 5},
        {x: 12, y: 4},
        {x: 13, y: 9},
        {x: 14, y: 1},
        {x: 15, y: 7},
        {x: 16, y: 6},
        {x: 17, y: 3},
        {x: 18, y: 2},
        {x: 19, y: 0}
    ];



    return (<>


        <FormBox title='Ad Groups'>


            {step == 0 &&

            (<>

                <ShowAzGroups step={step} setStep={setStep}/>
            </>)


            }


            {step == 1 &&

            (<>

                <CreateAzGroup step={step} setStep={setStep} />

            </>)


            }

        </FormBox>

        <SideComponent>
            <SideComponentBox >
                <header>
                    <h2>Combined Ad Sales</h2>
                </header>
                <FlexibleXYPlot height={200} style={{width:'100%'}}>
                    <VerticalBarSeries data={data} />
                </FlexibleXYPlot>
            </SideComponentBox>

        </SideComponent>




    </>);
};


export default AzGroups;