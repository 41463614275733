import React, {useRef, useEffect, useState} from 'react';
import FormBox from "../../page-utils/FormBox";
import {Link, useParams, useRouteMatch} from "react-router-dom";
import usePaginator from "../../../custom-hooks/usePaginator";
import {Msg} from "../../page-utils";
import Label from "../../page-utils/Label";
import useFetchApi, {RequestStatusEnum} from "../../../custom-hooks/useFetchApi";
import PaginatorNavigator from "../../page-utils/PaginatorNavigator";
import {Button} from "../../components/buttons";
import {TextBox} from "../../components/TextBoxes";
import ApiMsg from "../../page-utils/ApiMsg";




function ShowAzRates({step, setStep, groupId, ...props}){
    const { url, path } = useRouteMatch();




    const {requestStatus, responseData, page, setPage, totalPages, rowColors} = usePaginator('get_ad_rates', 0, {'group_id':groupId});





    return (<>
        <Msg type='info'>
            Schedule ads and manage the rates you pay to influencers
        </Msg>




        <Label>
            {requestStatus == RequestStatusEnum.LOADING &&
            <Label>
                <Msg type='loading'>Loading...</Msg>
            </Label>
            }



            {requestStatus == RequestStatusEnum.DONE &&


            (responseData.data.rows.length>0 ?


                    (<>
                        {responseData.data.rows.map((item, i)=>{

                            return (
                                <Link key={item.id} to={`${url}/rates/${item.id}`}>
                                    <Label key={item.id} style={rowColors[i]} className='width100 rows rows-groups-grid'>
                                        <span className='row-title'>
                                            {item.platform_handle}
                                        </span>

                                        <span className='variants-count pull-right'>
                                            ${item.price}
                                        </span>



                                    </Label>
                                </Link>



                            )

                        })}


                        <PaginatorNavigator page={page} setPage={setPage} totalPages={totalPages}/>
                    </>)

                    :

                    <article>
                        No ad rates created yet..
                    </article>

            )

            }





        </Label>
        <Label >
            <Button template='default' icon='plus' onClick={()=>{setStep(step+1)}}>New Ad Rate</Button>
        </Label>
    </>);
}


function CreateAzRate({step, setStep, groupId, ...props}){
    const [requestData, setRequestData] = useState({})
    const {requestStatus, responseData} = useFetchApi('new_ad_rate', requestData);


    const [formObj, setFormObj] = useState({platform_handle:'', price:'', ad_length_in_hours:'', allowed_post_start_hour:'11 AM', allowed_post_end_hour:'2 PM', group_id:groupId});


    const [startMeridiem, setStartMeridiem] = useState('AM')
    const [endMeridiem, setEndMeridiem] = useState('AM')


    function onChange(e){


        let formattedValue = e.target.value;


        let curPos = 0;
        switch (e.target.name){


            case 'allowed_post_start_hour':
            case 'allowed_post_end_hour':


                let meridiem_ = e.target.name == 'allowed_post_start_hour' ? startMeridiem : endMeridiem;
                if(e.target.value.toLowerCase().indexOf('a')!==-1 && e.target.value.toLowerCase().indexOf('pm')!==-1){
                    meridiem_ = 'AM'
                }

                if(e.target.value.toLowerCase().indexOf('p')!==-1 && e.target.value.toLowerCase().indexOf('am')!==-1){
                    meridiem_ = 'PM'
                }

                if(e.target.name == 'allowed_post_start_hour'){
                    setStartMeridiem(meridiem_)

                } else {
                    setEndMeridiem(meridiem_)
                }


                formattedValue = (e.target.value.replace(/[^0-9]/g,''));
                if((e.target.value.replace(/[^0-9]/g,''))>0  || parseInt(e.target.value.replace(/[^0-9]/g,''))===0){
                    formattedValue =  ((e.target.value).replace(/[^0-9]/g,'').replace(/\B(?=(\d{3})+(?!\d))/g, ","));


                    if(formattedValue>12 || formattedValue<=0){
                        formattedValue = 1;
                    }


                    curPos = formattedValue.length;
                    formattedValue += ` ${meridiem_}`;

                   /* if(formattedValue>12 || e.target.value==0){
                        //curPos = 1;
                        formattedValue = 'Ad is Permanent';
                    } else {
                        curPos = formattedValue.length;
                        formattedValue += ' hour(s)';
                    }*/



                    //e.target.setSelectionRange(0, 0)




                } else if(e.target.value=='$') {
                    formattedValue = '';
                }

                break;


            case 'ad_length_in_hours':
                formattedValue = (e.target.value.replace(/[^0-9]/g,''));
                if((e.target.value.replace(/[^0-9]/g,''))>0  || parseInt(e.target.value.replace(/[^0-9]/g,''))===0){
                    formattedValue =  ((e.target.value).replace(/[^0-9]/g,'').replace(/\B(?=(\d{3})+(?!\d))/g, ","));


                    if(formattedValue>99 || e.target.value==0){
                        curPos = 0;
                        formattedValue = 'Ad is Permanent';
                    } else {
                        curPos = formattedValue.length;
                        formattedValue += ' hour(s)';
                    }



                    //e.target.setSelectionRange(0, 0)




                } else if(e.target.value=='$') {
                    formattedValue = '';
                }

                break;

            case 'price':
                formattedValue = (e.target.value.replace(/[^0-9]/g,''));
                if((e.target.value.replace(/[^0-9]/g,''))>0){
                    formattedValue = '$' + ((e.target.value).replace(/[^0-9]/g,'').replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                } else if(e.target.value=='$') {
                    formattedValue = '';
                }

                break;

            case 'platform_handle':

                if(e.target.value.toString().replace('@', '').length>0){

                    formattedValue = '@' + (e.target.value.toString().replaceAll('@', ''));
                } else if(e.target.value=='$') {
                    formattedValue = '';
                }

                break;
        }


        setFormObj({...formObj, [e.target.name]:formattedValue });

        if(curPos){
            setTimeout(function(){
                e.target.setSelectionRange(curPos, curPos)
            },0)
        }



    }

    function onSubmit(){
        setRequestData(formObj);
    }



    useEffect(function(){







    }, [formObj.price])

    useEffect(function(){

        if(responseData.success){
            setStep(0);
        }
    }, [responseData]);

    return (<>
        <Msg type='info'>
            Enter the username of the influencer you wish to schedule ads with, and the price for ads you have privately negotiated with them.
        </Msg>

        <Label>
            <TextBox className='width100' placeholder={`Influencer's username`} name='platform_handle' onChange={onChange} value={formObj.platform_handle}/>
        </Label>

        <div className='label'>
            <header>
                <h2>Negotiated Ad Details</h2>
            </header>
            <div className={'form-box-section'}>
                <div>
                    <header>
                        <h3>Ad Price</h3>
                    </header>
                    <TextBox className='width100' placeholder={`Negotiated ad price`} name='price' onChange={onChange} value={formObj.price}/>
                </div>
                <div>
                    <header>
                        <h3>Ad Length</h3>
                    </header>
                    <TextBox className='width100' placeholder={`Ad length in hours`} name='ad_length_in_hours' onChange={onChange} value={formObj.ad_length_in_hours}/>
                </div>
            </div>
        </div>



        <div className='label'>
            <header>
                <h2>Allowed Timeframe to Post</h2>
            </header>
            <div className={'form-box-section'}>
                <div>
                    <header>
                        <h3>Min Time of Day</h3>
                    </header>
                    <TextBox className='width100' placeholder={`Min Time`} name='allowed_post_start_hour' onChange={onChange} value={formObj.allowed_post_start_hour}/>
                </div>
                <div>
                    <header>
                        <h3>Max Time of Day</h3>
                    </header>
                    <TextBox className='width100' placeholder={`Max Time`} name='allowed_post_end_hour' onChange={onChange} value={formObj.allowed_post_end_hour}/>
                </div>
            </div>
        </div>


        <Label className='input-grid'>
            <Button template='default' onClick={onSubmit} icon='plus'>Create new ad rate</Button>
            <Button template='default' onClick={()=>{setStep(step-1)}}>Cancel</Button>
        </Label>

        <ApiMsg requestStatus={requestStatus} responseData={responseData}/>
    </>);
}

function AzRates() {
    const [step, setStep] = useState(0);


    const { groupId } = useParams();


    return (<>


        <FormBox title={`Schedule Ads`}>



                  {step == 0 &&

                  (<>

                      <ShowAzRates step={step} setStep={setStep} groupId={groupId}/>
                  </>)


                  }


                  {step == 1 &&

                  (<>
                      <CreateAzRate step={step} setStep={setStep} groupId={groupId}/>

                  </>)


                  }

        </FormBox>



</>);
}


export default AzRates;