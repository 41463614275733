import React, { useEffect, useState} from "react";
import {TextBox} from "./components/TextBoxes/";
import {Button} from "./components/buttons";
import FormBox from "./page-utils/FormBox";
import Msg from "./page-utils/Msg";
import Label from "./page-utils/Label";
import {Redirect, useParams} from 'react-router-dom';
import useFetchApi, {RequestStatusEnum} from "../custom-hooks/useFetchApi";
import ApiMsg from "./page-utils/ApiMsg";
import useFetchProfile from "../custom-hooks/useFetchProfile";
import Footer from "./page-sections/Footer";
import Body, {BodyTemplates} from "./page-sections/Body";
import Helpers from "../helpers";
import {encode} from "html-entities";
import SocialLoginButton from "./components/buttons/SocialLoginButton";




const SelectedLoginMethodsEnum = {
    UNDECIDED: 0,
    LOGIN_EMAIL:1,
    SIGNUP_EMAIL:2,
}



const ContinueWithEmailType = {
    LOGIN:'login',
    SIGNUP:'signup',
}

const LoginMethods = {
    EMAIL:'email',
    GOOGLE:'google',
    FACEBOOK:'facebook'
}


/*function LoginEmail({SelectedLoginMethod, setSelectedLoginMethod, ...props}){

    const [startFetch, setStartFetch] = useState(false);
    const {fetchingProfile, state} = useFetchProfile(startFetch, fetchProfileCallBack);



    const [redirect, setRedirect] = useState(false);

    const [requestData, setRequestData] = useState({})
    const {requestStatus, responseData} = useFetchApi('login', requestData);


    const [formObj, setFormObj] = useState({email:'', pass:''});


    function onChange(e){
        setFormObj({...formObj, [e.target.name]:e.target.value });


    }



    function onSubmit(){
        //e.preventDefault();

        setRequestData(formObj);

    }


    function fetchProfileCallBack(){
        let to = setTimeout(function(){
            setRedirect(true);
        }, 2000);
    }

    useEffect(()=>{
        if(Object.keys(responseData).length){

            if (responseData.success){

                //Start fetching of profile data
                setStartFetch(true);

            }

        }
    }, [ responseData]);


    function handleKeyPress(e) {
        if (e.key === 'Enter') {
            onSubmit()
        }
    }

    return (<>



        <FormBox className='login-wrap' title='Login'>

            <Label>
                <Msg type='info'>
                    Enter your email and password
                </Msg>
            </Label>

            <Label>
                <TextBox template='login' className='width100' name='email' onChange={onChange}  onKeyPress={handleKeyPress} placeholder='Enter email' value={formObj.email}/>
            </Label>
            <Label>
                <TextBox template='login' type='password' className='width100' name='pass' onChange={onChange}  onKeyPress={handleKeyPress} placeholder='Enter password' value={formObj.pass}/>
            </Label>
            <Label>
                <Button template='login-highlight' onClick={onSubmit}>Login</Button>
            </Label>

            <ApiMsg requestStatus={requestStatus} responseData={responseData}/>


            <Label>
                <Msg type='info'>
                    Don't have an account? Click <span className='text-button' onClick={()=>(setSelectedLoginMethod(SelectedLoginMethodsEnum.UNDECIDED))}>HERE</span> to signup...
                </Msg>
            </Label>

            {redirect &&
            <Redirect to="/create" />
            }

        </FormBox>


    </>);
}*/


// const handleSocialLogin = (user) => {
//     console.log(user);
// };
//
// const handleSocialLoginFailure = (err) => {
//     console.error(err);
// };


function ContinueWithEmail({formObj, setFormObj, fetchObj, fetchProfileObj, SelectedLoginMethod, setSelectedLoginMethod, ...props}){
    const {requestData, setRequestData, requestStatus, responseData} = fetchObj;
    const {startFetch, setStartFetch, fetchingProfile, state} = fetchProfileObj;


    /*const [startFetch, setStartFetch] = useState(false);
    const {fetchingProfile, state} = useFetchProfile(startFetch, fetchProfileCallBack);*/



    /*const [redirect, setRedirect] = useState(false);*/



    /*const [requestData, setRequestData] = useState({})
    const {requestStatus, responseData} = useFetchApi('continue_with_email', requestData);*/


    const [hasAccountRequestData, SetHasAccountRequestData] = useState({})
    const {requestStatus: hasAccountRequestStatus, responseData: hasAccountResponseData} = useFetchApi('has_account', hasAccountRequestData);


    const [isNewAccount, setIsNewAccount] = useState(false)

    const [formError, setFormError] = useState(false)

    const [formStep, setFormStep] = useState(0)





    function onChange(e){

        /*if(e.target.name=='email'){

        }*/

        setFormObj({...formObj, [e.target.name]:e.target.value });


    }



    function onSubmit(){
        //e.preventDefault();

        if(formStep == 0){

            //setFormStep(1)
            console.log(formStep)

            if(formObj.email.indexOf('@') !== -1 && formObj.email.indexOf('.') !== -1){
                setFormError(false)
                SetHasAccountRequestData({ email: formObj.email })


            } else {
                setFormError('Please enter an email address to continue with...')
            }


        } else if(formStep == 1){

            setRequestData(formObj);

        }

    }

    /*function fetchProfileCallBack(){
        let to = setTimeout(function(){
            setRedirect(true);
        }, 2000);
    }*/


    //Attempt to login or signup...
    useEffect(()=>{
        if(Object.keys(responseData).length){

            if (responseData.success){
                //Start fetching of profile data
                setStartFetch(true);
            }

        }
    }, [ responseData]);


    //Check if account exists or not, if it doesnt, update the form to show you are logging in and not signing up...
    useEffect(()=>{
        if(Object.keys(hasAccountResponseData).length){

            if(hasAccountResponseData.data.registered_with_other_method){
                setFormError('Error, this email is used with an account via a different login method. Please use the login method originally associated with this email or use a different email.')
            } else {
                setFormError(false);
                setIsNewAccount(!hasAccountResponseData.data.has_account);
                setFormObj({...formObj, login_method: 'email', continue_with_email_type:(hasAccountResponseData.data.has_account ? ContinueWithEmailType.LOGIN : ContinueWithEmailType.SIGNUP)})

                setFormStep(1)
            }


        }
    }, [ hasAccountResponseData]);


    function handleKeyPress(e) {
        if (e.key === 'Enter') {
            onSubmit()
        }
    }

    return (<>

        <FormBox className='login-wrap' title='Continue with email'>





            {formStep == 0 && <>
                <Label>
                    <Msg type='info'>
                        Enter your email...
                    </Msg>
                </Label>

                <Label>
                    <TextBox template='login' spellCheck="false" autoCorrect="off" autoComplete="off" className='width100' name='email' onChange={onChange} onKeyPress={handleKeyPress} placeholder='Enter email' value={formObj.email}/>
                </Label>

                <Label>
                    {hasAccountRequestStatus == RequestStatusEnum.LOADING &&

                    (<>

                        <Button template='login'>Please Wait..</Button>

                    </>)

                    ||

                    (<>

                        <Button template='login-highlight' onClick={onSubmit}>Continue</Button>

                    </>)

                    }

                </Label>

                {formError && <>

                    <Label>
                        <Msg type='error'>{formError}</Msg>
                    </Label>

                </>}
            </>}


            {formStep == 1 && <>

                <Label>
                    <Msg type='info'>
                        {isNewAccount ? 'Create a password...' : 'Enter your password...'}
                    </Msg>
                </Label>

                <Label>
                    <TextBox template='login' type='password' className='width100' name='pass' onChange={onChange} onKeyPress={handleKeyPress} placeholder={isNewAccount ? 'Create a password' : 'Enter your password'} value={formObj.pass}/>
                </Label>

                <Label className='input-grid'>
                    <Button template='login-highlight' onClick={onSubmit}>{isNewAccount ? 'Create Account' : 'Login' }</Button>
                    <Button template='login' onClick={()=>{setFormStep(0)}}>Go back</Button>
                </Label>
            </>}


            <ApiMsg requestStatus={requestStatus} responseData={responseData}/>


            {/*<Label>
                <Msg type='info'>
                    Already have an account? Click <span className='text-button' onClick={()=>(setSelectedLoginMethod(SelectedLoginMethodsEnum.LOGIN_EMAIL))}>HERE</span> to login...
                </Msg>
            </Label>*/}





        </FormBox>

    </>);
}



function Login(){

    const { redirectReason } = useParams();

    const [selectedLoginMethod, setSelectedLoginMethod] = useState(SelectedLoginMethodsEnum.UNDECIDED);


    const [startFetch, setStartFetch] = useState(false);
    const {fetchingProfile, state} = useFetchProfile(startFetch, fetchProfileCallBack);


    const [requestData, setRequestData] = useState({})
    const {requestStatus, responseData} = useFetchApi('continue_with_login', requestData);

    const [formObj, setFormObj] = useState({email:'', pass:'', login_method:'email'}); //, continue_with_email_type: ContinueWithEmailType.SIGNUP

    const [formError, setFormError] = useState(false)


    const [redirect, setRedirect] = useState(false);



    function fetchProfileCallBack(){
        let to = setTimeout(function(){
            setRedirect(true);
        }, 2000);
    }


    function handleSocialLogin(user){
        console.log(user)

        //setFormObj({...formObj, 'email':user.email, 'pass':user._profile.id, continue_with_email_type: user._provider});
        //setFormObj()
        setRequestData({'email':user._profile.email, 'pass':user._profile.id, login_method: user._provider, access_token:user._token.accessToken});
    }


    function handleSocialLoginFailure(err){

        setFormError('Error, could not login.')
        //console.log(err)

    }

    useEffect(()=>{
        if(Object.keys(responseData).length){

            if (responseData.success){
                //Start fetching of profile data
                setStartFetch(true);
            } else {
                setFormError(responseData.errors[0])
            }

        }
    }, [ responseData]);

    useEffect(()=>{

        setFormError(false)
    }, [selectedLoginMethod])



    return (<>

        <Body template={BodyTemplates.MAIN}>



            {selectedLoginMethod == SelectedLoginMethodsEnum.UNDECIDED && <>


                <FormBox className='login-wrap' title='Login to continue'>

                    {redirectReason=='generate' && Helpers.get_cookie('generate_text') && <>

                        <Label>
                            <Msg type='loading'>
                                <span className='bold color-white'>"{encode(Helpers.friendly_generate_text(Helpers.get_cookie('generate_text')))}"</span><br/>
                                <span className='italic font12'>Is being generated... Please login to see the results.</span>

                            </Msg>
                        </Label>
                    </>}


                    {formError && <>

                        <Label>
                            <Msg type='error'>{formError}</Msg>
                        </Label>

                    </>}


                    <Label>
                        <SocialLoginButton className='width100 mobile-font14' template='login'
                                           provider="google"
                                           appId="266998334207-vbp4dqkpnfo6kuogbp71l6ihl6v53h8h.apps.googleusercontent.com"
                                           onLoginSuccess={handleSocialLogin}
                                           onLoginFailure={handleSocialLoginFailure}
                        >
                            Continue with Google
                        </SocialLoginButton>
                    </Label>

                    <Label>
                        <SocialLoginButton className='width100 mobile-font14' template='login'
                                           provider="facebook"
                                           appId="406603814257944"
                                           onLoginSuccess={handleSocialLogin}
                                           onLoginFailure={handleSocialLoginFailure}
                        >
                            Continue with Facebook
                        </SocialLoginButton>
                    </Label>


                    {/*<Label>
                        <Button className='width100' template='login' onClick={()=>{}}>Continue with Google</Button>
                    </Label>

                    <Label>
                        <Button className='width100' template='login' onClick={()=>{}}>Continue with FaceBook</Button>
                    </Label>*/}

                    <Label>
                        <Button className='width100 mobile-font14' template='login' onClick={()=>{setSelectedLoginMethod(SelectedLoginMethodsEnum.SIGNUP_EMAIL)}}>Continue with Email</Button>
                    </Label>

                </FormBox>


            </>}


            {selectedLoginMethod == SelectedLoginMethodsEnum.SIGNUP_EMAIL && <>
                <ContinueWithEmail formObj={formObj}
                                   setFormObj={setFormObj}
                                   fetchProfileObj={{startFetch, setStartFetch, fetchingProfile, state}}
                                   fetchObj={{requestData, setRequestData, requestStatus, responseData}}
                                   selectedLoginMethod={selectedLoginMethod}
                                   setSelectedLoginMethod={setSelectedLoginMethod}
                />
            </>}

            {/*{selectedLoginMethod == SelectedLoginMethodsEnum.LOGIN_EMAIL && <>
                <LoginEmail selectedLoginMethod={selectedLoginMethod} setSelectedLoginMethod={setSelectedLoginMethod}/>
            </>}*/}


            {selectedLoginMethod != SelectedLoginMethodsEnum.UNDECIDED && <>
                <div className='width100 textalign-center'>
                    <Button className='margin-auto' template='login' onClick={()=>{setSelectedLoginMethod(SelectedLoginMethodsEnum.UNDECIDED)}}>Back to Other Login Methods</Button>
                </div>
            </>}



            {redirect &&
            <Redirect to="/create" />
            }


            {/*<LoginEmail/>*/}
        </Body>
    </>);
}

export default Login;