import React, {useState, useEffect} from 'react';
import Footer from "./page-sections/Footer";
import Body, {BodyTemplates} from "./page-sections/Body";
import FormBox from "./page-utils/FormBox";
import {Msg} from "./page-utils";
import Label from "./page-utils/Label";
import {Button} from "./components/buttons";
import {TextBox} from "./components/TextBoxes";
import useFetchApi from "../custom-hooks/useFetchApi";
import ApiMsg from "./page-utils/ApiMsg";
import AzGroups from "./page-sections/Panel/AzGroups";
import {HashRouter as Router, Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import AzVariants from "./page-sections/Panel/AzVariants";
import AzRates from "./page-sections/Panel/AzRates";
import ManageRate from "./page-sections/Panel/AzRates/ManageRate";
import PageMap from "./page-utils/PageMap";
import Error404 from "./Error404";
import ContentBot from "./page-sections/Panel/ContentBot";
import Overview from "./page-sections/Panel/Overview";



function Panel(){

    const { url, path } = useRouteMatch();





    const [step, setStep] = useState(0);


    //Get profile
    const [requestData, setRequestData] = useState({})
    const {requestStatus, responseData} = useFetchApi('new_ad_group', requestData);


    const [formObj, setFormObj] = useState({group_name:''});


    function onChange(e){
        setFormObj({...formObj, [e.target.name]:e.target.value });

    }

    function onSubmit(){
        setRequestData(formObj);
    }




    return (<>


        <Body template={BodyTemplates.MAIN_MENU_SIDE} SideComponent={<></>}>

            {/*<AzGroups/>*/}

            <Switch>

                <Route exact path='/panel'>
                    <Redirect to={`/panel/overview`} />
                </Route>

                {/*<Route exact path={`${url}/manage`} render={()=>{

                    return <AzGroups/>
                }}/>*/}

                <Route exact path={`${url}/overview`}>

                    <PageMap navLocations='Panel' />


                    <Overview/>
                </Route>



                <Route exact path={`/panel/manage/:groupId`}>

                    <PageMap navLocations='Panel:panel/Manage Ads' />

                    <AzRates/>

                    <AzVariants/>

                </Route>


                <Route exact path={`/panel/manage/:groupId/rates/:rateId`}>

                    <PageMap navLocations='Panel:panel/Manage Ads:manage/Manage Rates' />

                    <ManageRate/>

                </Route>


                <Route exact path='/panel/content-bot'>
                    <Redirect to={`/panel/content-bot`} />

                    <ContentBot/>
                </Route>


                <Route path={'*'}>
                        <Error404/>
                </Route>




            </Switch>

        </Body>




    </>);

}


export default Panel;