import React, {useContext} from "react";
import AppContext from "../../context/AppContext";

function PopupScreen(props) {

    /*const {state, dispatch} = useContext(AppContext);


    return (
        <>
            {state.UI.popupScreen.show && (
                <div className='popup-screen' onClick={() => {
                    //UI.popupScreen.setShow(false);
                    dispatch({type:'HIDE_POPUPSCREEN'});
                }}>{state.UI.popupScreen.content}</div>
            )}
        </>)*/

    return <></>
}

export default PopupScreen;