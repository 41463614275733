import React from 'react';
import Button from "./Button";
import SocialLogin from "react-social-login";


function SocialLoginButton(props) {
    const { children, triggerLogin, ...otherProps } = props;


    return (<>
        <Button onClick={triggerLogin} {...otherProps}>
            {children}
        </Button>
    </>);
}


export default SocialLogin(SocialLoginButton);