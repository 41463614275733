import React, {useContext, useEffect} from 'react';
import AppContext from "../../context/AppContext";
import BodyContext from "../../context/BodyContext";

function SideComponent(props) {

    const {bodyState, bodyDispatch} = useContext(BodyContext);


    useEffect(function(){
        //alert('bodyDispatch:' + props.children.toString());
        bodyDispatch({type:'ADD_SIDE_COMPONENT', payload:props});

        return () => {
            bodyDispatch({type:'CLEAR_SIDE_COMPONENTS', payload:{}});
        }
    }, [props])



    //Dont render here, instead dispatch elements to be rendered on side panel

    return (<></>);
}


export default SideComponent;