import {RequestStatusEnum} from "../../custom-hooks/useFetchApi";
import Msg from "./Msg";
import {React} from "react";

function ApiMsg({requestStatus, responseData}){
    //const {requestStatus, responseData, ...otherProps} = props;


    return (<>


        {requestStatus == RequestStatusEnum.LOADING &&
        <Msg type='loading'>Loading</Msg>
        }

        {requestStatus == RequestStatusEnum.DONE && ('success' in responseData) &&
        <Msg type={responseData.success == true ? 'success' : 'error'}>

            {responseData.details &&

            <div className='width100'>{responseData.details}</div>

            }

            {(('errors' in responseData) && Object.keys(responseData.errors).length) &&

            responseData.errors.map((error)=>{
                return (<>
                    <div className='width100'> {error}</div>
                </>)
            })

            }

        </Msg>

        }

    </>)
}

export default ApiMsg;