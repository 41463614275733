import React, { useEffect, useState} from "react";
import {TextBox} from "./components/TextBoxes/";
import {Button} from "./components/buttons";
import FormBox from "./page-utils/FormBox";
import Msg from "./page-utils/Msg";
import Label from "./page-utils/Label";
import useFetchApi, {RequestStatusEnum} from "../custom-hooks/useFetchApi";
import ApiMsg from "./page-utils/ApiMsg";
import { Redirect } from 'react-router-dom';
import useFetchProfile from "../custom-hooks/useFetchProfile";
import Body, {BodyTemplates} from "./page-sections/Body";






function Logout(){

    const [startFetch, setStartFetch] = useState(false);
    const {fetchingProfile, state} = useFetchProfile(startFetch, fetchProfileCallBack);

    const [redirect, setRedirect] = useState(false);

    const [requestData, setRequestData] = useState(true)
    const {requestStatus, responseData} = useFetchApi('logout', requestData);


    function fetchProfileCallBack(){

        //alert(state.profile.loggedIn);

        let to = setTimeout(function(){
            setRedirect(true);
        }, 2000);
    }



    useEffect(()=>{
        if(Object.keys(responseData).length){

            if (responseData.success){


                //Fetch profile
                 setStartFetch(true);
            }

        }
    }, [ responseData]);

    return (<>

        <Body template={BodyTemplates.MAIN}>
            <FormBox className='login-wrap' title='LOGOUT'>

                <Label>
                    <Msg type='loading'>
                        Logging out...
                    </Msg>
                </Label>

                {redirect &&
                <>
                    <Redirect to='/login'/>
                </>
                }

            </FormBox>
        </Body>



    </>);
}


export default Logout;