import {useState, useEffect, useCallback, useContext} from 'react';
import useFetch, {RequestStatusEnum} from "./useFetch";
import AppContext from "../context/AppContext";
import useFetchApi from "./useFetchApi";
export {RequestStatusEnum as RequestStatusEnum} from './useFetch';


function useFetchProfile(startFetch, fetchProfileCallBack) {

    const [fetchingProfile, setFetchingProfile] = useState(startFetch);

    //Get profile
    const [requestData, setRequestData] = useState(startFetch)
    const {requestStatus, responseData} = useFetchApi('get_profile', requestData);

    const {state, dispatch} = useContext(AppContext);

    useEffect(()=>{
        //When startFetch is changed from parent, init API request
        setRequestData(startFetch);


    }, [startFetch]);




    useEffect(()=>{
        //Set profile object in app context
        if('success' in responseData){
           // dispatch({type: 'SET_PROFILE', payload:responseData.data.profile}); //payload:{loggedIn:responseData.success, ...responseData.data.profile }});

            dispatch({type: 'SET_DOM_DATA', payload:responseData.data});
            //alert(JSON.stringify(state.profile));


        }
    }, [responseData])



    useEffect(()=>{
        //callback once request is completed. Use state to get success of login.
        if(requestStatus == RequestStatusEnum.DONE){
            if(typeof fetchProfileCallBack === 'function'){
                fetchProfileCallBack();
            }
        }
    }, [requestStatus])


    useEffect(()=>{
        if(requestStatus == RequestStatusEnum.DONE){
            setFetchingProfile(false);
        }
    }, [requestStatus])




    return { fetchingProfile, state, dispatch };
};


export default useFetchProfile;
