import { useState, useEffect, useCallback } from 'react';


export const RequestStatusEnum = {
    IDLE:1,
    LOADING:2,
    DONE:3
}


export const RequestDataTypeEnum = {
    JSON:1,
    FILE_UPLOAD:2,
}




function useFetch (url, requestData, requestDataType) {
    //isPost = isPost || false;

    console.log(url)

    requestDataType = requestDataType || RequestDataTypeEnum.JSON


    const [requestStatus, setRequestStatus] = useState(RequestStatusEnum.IDLE);
    const [responseData, setResponseData] = useState({});

    const controller = new AbortController();


    // POST request using fetch inside useEffect React hook
    const requestOptions = ()=>{
        if(requestDataType == RequestDataTypeEnum.JSON) {
            return {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                //"Content-Type": "application/x-www-form-urlencoded" #Use if you want to craft post data the traditional way
                body: JSON.stringify(requestData), //Actual request body as a string
                signal: controller.signal
            }
        } else {
            return {
                method: 'POST',
                //"Content-Type": "application/x-www-form-urlencoded" #Use if you want to craft post data the traditional way
                body: requestData, //Actual request body as a
                signal: controller.signal

            }
        }

    }



    const getResponse = useCallback(async (requestOptions) => {
        //alert(JSON.stringify(requestOptions));

        const response = await fetch(url, requestOptions());
        const data = await response.json();

       /* const response = await axios.post(url, JSON.parse(requestOptions.body));
        const data = await response.data;*/
        setResponseData(data);
        setRequestStatus(RequestStatusEnum.DONE);


    }, [url]);

    useEffect(() => {

        //Init request if the request type is a file upload request, and if the formData is set
        let uploadFiles = false;
        if(requestDataType == RequestDataTypeEnum.FILE_UPLOAD && requestData!==false){
            uploadFiles = true
        }

        if(requestData === true || Object.keys(requestData).length || uploadFiles){
            //alert('requestData: ' + requestData);
            setRequestStatus(RequestStatusEnum.LOADING);
            getResponse(requestOptions)

        }

        //Cancel previous request if new one is made
        return function(){
            controller.abort();
        }

    }, [url, getResponse, requestData]);
    return { requestStatus, responseData };
};


export default useFetch;