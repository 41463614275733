import React, {useContext, useState} from 'react';
import FormBox from "../../page-utils/FormBox";
import {Button} from "../../components/buttons";
import useFetchApi, {RequestStatusEnum} from "../../../custom-hooks/useFetchApi";
import Label from "../../page-utils/Label";
import {Msg} from "../../page-utils";
import {TextBox} from "../../components/TextBoxes";
import CheckBox from "../../components/CheckBoxes/CheckBox";
import AppContext from "../../../context/AppContext";
const nodeBase64 = require('nodejs-base64-converter');


function ContentBot() {

    const [requestData, setRequestData] = useState({})
    const {requestStatus, responseData} = useFetchApi('content_bot', requestData);

    const {state: AppContextState, dispatch: AppContextDispatch} = useContext(AppContext);




    function onSubmit(){
        setRequestData({'action':'find_content'});
    }

    function  downloadFile(url, name) {
        fetch(url)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = name;
                    a.click();
                });
                //window.location.href = response.url;
            });
    }



    return (<>





        <FormBox title={`Content Finder`}>


            <label>
                <Button className={'width100'} onClick={onSubmit}>Find Content</Button>
            </label>


            {requestStatus == RequestStatusEnum.LOADING &&
            <Label>
                <Msg type='loading'>Content bot is finding content...</Msg>
            </Label>
            }


            {requestStatus == RequestStatusEnum.DONE &&
            <>

                {responseData.data.success ?
                    <>
                        <Label>
                            <Msg type='info'>
                                From Page: {responseData.data.page_data.current_page}<br/>
                                Page Activity Score: {responseData.data.page_data.page_activity_level}<br/>
                                Likes: {responseData.data.post_data.likes}<br/>
                                Comments: {responseData.data.post_data.comments}<br/>
                                Views: {responseData.data.post_data.views}<br/>
                                Caption: {nodeBase64.decode(responseData.data.post_data.original_caption)}<br/>
                                Top Comment: {nodeBase64.decode(responseData.data.post_data.top_comment)}<br/>
                            </Msg>
                        </Label>

                        <Label>
                            {/*<a rel="nofollow noopener" target="_blank" download={'v-' + responseData.data.post_data.media_file_name} href={AppContextState.API.url + responseData.data.post_data.public_media_path}>Click to Download</a>
                            */}
                            <Button onClick={()=>{downloadFile(AppContextState.API.url + responseData.data.post_data.public_media_path,  responseData.data.post_data.media_file_name)}}>Download</Button>
                        </Label>



                        <Label>
                            {responseData.data.post_data.media_type == 'video'?

                                <video controls style={{width:'100%'}} >

                                    <source src={AppContextState.API.url + responseData.data.post_data.public_media_path}
                                            type="video/mp4"/>

                                    Sorry, your browser doesn't support embedded videos.

                                </video>

                                :

                                <img style={{width:'100%'}}  src={AppContextState.API.url + responseData.data.post_data.public_media_path}/>
                            }
                        </Label>

                    </>

                    :

                    <>
                        <Msg type='error'>{responseData.details}</Msg>
                    </>
                }

            </>

            }




        </FormBox>





    </>);
}


export default ContentBot;