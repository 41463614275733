import React from 'react';
import FormBox from "./page-utils/FormBox";
import Label from "./page-utils/Label";
import {Msg} from "./page-utils";

function Error404() {
    return (<>
        <FormBox title={'Page not found.'}>
            <Label>
                <Msg type={'error'}>Error, Page not found.</Msg>
            </Label>
        </FormBox>
    </>);
}


export default Error404;