import React from "react";


function Label(props){
    const {className, children, ...otherProps} = props;
    return (<>
        <div className={`label ${className || ''}`} {...otherProps}>
            {children}
        </div>
    </>)
}

export default Label;

