import React from 'react';
import StripeCheckout from "./page-sections/StripeCheckout";
import FormBox from "./page-utils/FormBox";
import Body, {BodyTemplates} from "./page-sections/Body";

function GetTokens() {
    return (<>


        <Body template={BodyTemplates.MAIN}>
            <FormBox title='Checkout'>




                {/*<StripeCheckout/>*/}

            </FormBox>
        </Body>



    </>);
}


export default GetTokens;