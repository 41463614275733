import React from 'react';
import {NavButton} from "../components/buttons";

function NavMenu() {

    const navMenuButtons = [
        {
            'text':'Overview',
            'link':'/panel/overview',
            'icon':'calender'
        }/*,
        {
            'text':'Get Tokens',
            'link':'/tokens',
            'icon':'power'
        }*/
        /*{
            'text':'Top Creations',
            'link':'/panel/schedule',
            'icon':'calender'
        },*/
        /*{
            'text':'Content Bot',
            'link':'/panel/content-bot',
            'icon':'calender'
        },*/
    ]

    return (<>
        <aside className='page-grid-item page-grid-item-menu '>
            <div className='nav-menu-side'>
                {navMenuButtons.map((button, index)=>{
                    return (

                        <NavButton key={index} icon={button.icon} template='nav-menu' to={button.link}>
                            {button.text}
                        </NavButton>


                    )

                })}
            </div>
            {/*Menu*/}
        </aside>


    </>);
}


export default NavMenu;

