//import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {useContext, useState, useEffect} from 'react';
import {HashRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {Msg, PopupScreen} from "./page-utils";
import Head from "./page-sections/Head";
import React from "react";
import ScheduleNew from "./ScheduleNew";
import Create from "./Create";
import Login from "./Login";
import Signup from "./Signup";
import useFetchApi, {RequestStatusEnum} from "../custom-hooks/useFetchApi";
import AppContext from "../context/AppContext";
import Logout from "./Logout";
import FormBox from "./page-utils/FormBox";
import useFetchProfile from "../custom-hooks/useFetchProfile";
import Panel from "./Panel";
import Loading from "./Loading";
import Recent from "./Recent";
import GetTokens from "./GetTokens";

const ReactRouterSetup = () => {




    return (
        <Router>

            <PopupScreen/>


            {/*<div className='body-wrap'>*/}

                <Switch>
                    <Route exact path='/'>
                        <Redirect to='/panel'/>
                    </Route>

                    <Route path='/panel'>
                        <Panel/>
                    </Route>

                    <Route path='/create'>
                        <Create/>
                    </Route>

                    <Route path='/recent'>
                        <Recent/>
                    </Route>


                    <Route path='/schedule/:rateId/:rateIdHash'>
                        <ScheduleNew/>
                    </Route>

                    <Route path='/logout'>
                        <Logout/>
                    </Route>






                    <Route path='*'>
                        Error 404
                    </Route>
                </Switch>
            {/*</div>*/}

        </Router>
    );

};

export default ReactRouterSetup;