import React from 'react';


function Button({template, icon, ...props}) {
    const {className, children, ...otherProps} = props;
    template = template || 'default';
    let inputClass = '';


    function InnerButton(){
        return (<>
            <button type='button' className={'btn ' + inputClass + ' ' + (className || '')} {...otherProps}>{children}</button>
        </>)
    }



    switch (template){
        case 'default':
            inputClass = 'btn-default';
            break;
        case 'default-highlight':
            inputClass = 'btn-default btn-theme-green';
            break;
        case 'login':
        case 'onboard':

            inputClass = 'btn-onboard';
            break;
        case 'login-highlight':
        case 'onboard-highlight':

            inputClass = 'btn-onboard btn-theme-green';
            break;
        case 'nav-button-head':
            inputClass = 'btn-nav-head';
            break;
        case 'nav':
            inputClass = 'btn-nav';
            break;
        case 'nav-menu':
            inputClass = 'btn-nav-menu';
            break;
        case 'paginator':
            inputClass = 'btn-paginator';
            break;
    }

    return (<>
            <button type='button' className={'btn ' + inputClass + ' ' + (className || '')} {...otherProps}>

                {icon ?

                    (<>
                        <div className='icon-btn'>
                            <div className={`inner-icon-btn-icon icon-${icon}`}></div>
                            <div className='inner-icon-btn-text'>{children}</div>
                        </div>
                    </>)

                    :

                    (<>{children}</>)

                }


            </button>
    </>

    )
};



export default Button;