import React from 'react';
import {useRouteMatch} from "react-router-dom";
import usePaginator from "../../../custom-hooks/usePaginator";
import Label from "../../page-utils/Label";
import {RequestStatusEnum} from "../../../custom-hooks/useFetchApi";
import {Msg} from "../../page-utils";
import ImageFeedItem from "../../page-utils/ImageFeedItem";
import PaginatorNavigator from "../../page-utils/PaginatorNavigator";
import FormBox from "../../page-utils/FormBox";

function ImageFeed(props){

    const {personal, sideComponent, ...otherProps} = props;

    /*const [reducerState, dispatch] = useReducer(reducer, defaultState);*/
    const { url, path } = useRouteMatch();


    const {requestStatus, responseData, page, setPage, totalPages, rowColors} = usePaginator('get_feed', 0, {personal:personal});





    return (<>

        <header className='textalign-center'>
            <h2>{personal ? 'My Creations' : 'Community Creations'}</h2>
        </header>

        <Label>



            {requestStatus == RequestStatusEnum.LOADING &&
            <Label className='width40 margin-auto'>
                <Msg type='loading'>Loading...</Msg>
            </Label>
            }



            {requestStatus == RequestStatusEnum.DONE &&


            (responseData.data.rows.length>0 ?


                    (<>
                        {responseData.data.rows.map((item, i)=>{

                            return (




                                <ImageFeedItem key={item.id} sideComponent={sideComponent} image_id={item.id} title={item.text} user={item.user} timestamp={item.timestamp}/>
                                /*<Link to={`${url}/${item.id}`} key={item.id}>
                                    <Label key={item.id} style={rowColors[i]} className='width100 rows rows-groups-grid'>
                                        <div className='row-title'>
                                                <span className={`variants-count pull-right ${item.variants_count!=='0' ? 'highlighted' : ''}`}>
                                                    {item.variants_count} Ads
                                                </span>

                                            {item.group_name}


                                        </div>
                                        <div className='row-actions'>

                                        </div>

                                    </Label>
                                </Link>*/



                            )

                        })}


                        <PaginatorNavigator page={page} setPage={setPage} totalPages={totalPages}/>
                    </>)

                    :

                    <FormBox border={false}>
                        <article>
                            No creations yet..
                        </article>
                    </FormBox>

            )

            }





        </Label>
    </>);
}

export default ImageFeed;