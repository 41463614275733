import React from 'react';
import Button from "./Button";
import { Link, useLocation } from 'react-router-dom';


function NavButton({to, template, ...props}) {
    const location = useLocation();
    template = template || 'nav';

    return (<>
        <Link to={to}>
            <Button className={location.pathname.toString().startsWith(to) && 'selected'} template={template} {...props} />
        </Link>


    </>)
};


export default NavButton;