import React, {useState, useEffect, useContext} from "react";
import {Link} from "react-router-dom";
import Label from "../../page-utils/Label";

function LinearRadioBox({name, options, callback, ...props}) {
    const {children, ...otherProps} = props;

    const [selectedOption, setSelectedOption] = useState(options[0].value);

    function onChange(value){
        setSelectedOption(value);

        callback(name, value);
    }

    return (<>
        <div className='linear-radio-box' {...otherProps}>
            {options.map((option, i)=>{

                return (

                    <div key={i} className={`linear-radio-box-item ${selectedOption == option.value ? 'selected' : ''}`} key={i} onClick={()=>{onChange(option.value)}}>
                        {option.label}
                    </div>

                 )

            })}


        </div>
    </>);
}


export default LinearRadioBox;