import React, {useState, useEffect, useContext} from "react";
import {encode} from "html-entities";
import {Button} from "./components/buttons";
import Calendar from "react-calendar";
import {Msg, PopupScreen} from "./page-utils";
import Head from "./page-sections/Head";
import { useTrail, a } from 'react-spring'
import IgAnim from "./page-utils/IgAnim";
import FormBox from "./page-utils/FormBox";
import Label from "./page-utils/Label";
import Body, {BodyTemplates} from "./page-sections/Body";
import useFetchApi, {RequestStatusEnum} from "../custom-hooks/useFetchApi";
import {Redirect, useParams} from "react-router-dom";
import helpers from "../helpers";
import AppContext from "../context/AppContext";
import {TextBox} from "./components/TextBoxes";
import ApiMsg from "./page-utils/ApiMsg";
import Login from "./Login";
import FormBoxImage from "./page-utils/FormBoxImage";
import SideComponentBox from "./page-utils/SideComponentBox";
import {FlexibleXYPlot, VerticalBarSeries} from "react-vis";
import SideComponent from "./page-sections/SideComponent";
import ImageFeed from "./page-sections/Panel/ImageFeed";
import LinearRadioBox from "./components/buttons/LinearRadioBox";
import Helpers from "../helpers";





function Create() {


    const {state: AppContextState, dispatch: AppContextDispatch} = useContext(AppContext);




    const [requestData, setRequestData] = useState({'check_status':true})
    const {requestStatus, responseData} = useFetchApi('create_image', requestData);



    const [getImgRequestData, setGetImgRequestData] = useState({'image_id':0})
    const {requestStatus: getImgRequestStatus, responseData: getImgResponseData} = useFetchApi('get_image', getImgRequestData);


    const [formObj, setFormObj] = useState({text:encode(Helpers.get_cookie('generate_text')), resolution:'350x350', 'iterations':200});


    const [tokenCost, setTokenCost] = useState({'iterations_token_cost':1, 'resolution_token_cost':1})


    //const [imageUrl, setImageUrl] = useState('');

    const imageGenProgressInit = {image_url: '', progress_status: '', current_iterations:0, max_iterations:0}
    const [imageGenProgress, setImageGenProgress] = useState(imageGenProgressInit);


    const [redirectLogin, setRedirectLogin] = useState(false)




    function onChange(e){


        if(e.target.name=='text' && !AppContextState.profile.loggedIn && e.target.value.length<500){
            Helpers.set_cookie('generate_text', (e.target.value), 1);
        }


        setFormObj({...formObj, [e.target.name]:e.target.value });

    }

    function onSubmit(){

        if(AppContextState.profile.loggedIn){
            setImageGenProgress(imageGenProgressInit)
            setRequestData({...formObj, rand:Math.random() * (100000 - 1) + 1});
            Helpers.set_cookie('generate_text', '', 1);
        } else {
            setRedirectLogin(true);
        }

    }





    useEffect(()=>{


        //Set token cost
        if('create_options' in AppContextState.site_data){
            let iterations_token_cost = 1;
            let resolution_token_cost = 1;

            for(let i=0;i<=AppContextState.site_data.create_options.iterations.length-1;i++){
                if(formObj.iterations == AppContextState.site_data.create_options.iterations[i].value){
                    iterations_token_cost = AppContextState.site_data.create_options.iterations[i].token_cost;
                    //alert(formObj.iterations + ' - ' + AppContextState.site_data.create_options.iterations[i].token_cost)

                }
            }

            for(let i=0;i<=AppContextState.site_data.create_options.resolution.length-1;i++){
                if(formObj.resolution == AppContextState.site_data.create_options.resolution[i].value){
                    resolution_token_cost = AppContextState.site_data.create_options.resolution[i].token_cost;
                }
            }

            setTokenCost({'iterations_token_cost':iterations_token_cost, 'resolution_token_cost':resolution_token_cost})

        }






    }, [formObj]);




    function onChangeRadio(name, value){
        console.log(name)
        setFormObj({...formObj, [name]:value})

    }




    const [checkForImageTimes, setCheckForImageTimes] = useState(0); //Max times timer should tick

    useEffect(() => {


            if (checkForImageTimes > 0 && imageGenProgress.progress_status!='finished') {
                setTimeout(function(){


                    setCheckForImageTimes(checkForImageTimes - 1)
                    setGetImgRequestData({image_id: responseData.data.image_id, randy: Math.random() * (100000 - 1) + 1})


                    //console.log(checkForImageTimes)



                }, 1000*7); //Do not Set to 5 sec because i think the cloudflare keep alive was killing the tcp connection every 5 seconds and was causing the request to be broken? Not 100% verified but packet dump showed 2 requests from cloudflare and cloudflare wasnt able to get a response from origin
            } else {
               //Timer finished
            }

    }, [checkForImageTimes]);


    useEffect(()=> {


        if(getImgRequestStatus == RequestStatusEnum.DONE){
            if(getImgResponseData.success){
                //setImageUrl(getImgResponseData.data.image_url);
                setImageGenProgress(getImgResponseData.data)

            }
        }



    }, [getImgRequestStatus])




    useEffect(()=>{
        if(responseData.success && responseData.data.image_id){

            setCheckForImageTimes(50); //Check for image 50 times

            /*let timestamps = helpers.get_between_hours_timestamps(responseData.data.allowed_post_min_hour_timetstamp, responseData.data.allowed_post_max_hour_timetstamp, scheduleData.currentDate.getTime());

            setScheduleData({
                ...scheduleData,
                currentDate: new Date(responseData.data.min_date_timestamp * 1000),
                minDate: new Date(responseData.data.min_date_timestamp * 1000),
                maxDate: new Date(responseData.data.max_date_timestamp * 1000),
                allowedScheduleTimestamps: timestamps
            })
*/
        }


    }, [responseData])



    // useEffect(()=>{
    //      if(requestStatus == RequestStatusEnum.IDLE){ //Check if image was being generated before page was loaded
    //          if(getImgRequestStatus == RequestStatusEnum.DONE && getImgResponseData.success){
    //              setCheckForImageTimes(50);
    //          }
    //      }
    // }, [imageGenProgress])






    return (<>

        {redirectLogin &&
            <>
                <Redirect to="/login/generate" />
            </>
        }

        <Body template={AppContextState.profile.loggedIn ? BodyTemplates.MAIN_MENU_SIDE : BodyTemplates.MAIN} style={{position: 'relative'}}>

            <FormBox className='onboard textalign-center' border={true} title='Generate Image'>

                {/*<div  style={{position: 'fixed', top: '0px', left: '0px', right: '0px', zIndex: 999, margin: 'auto', background: 'rgba(1, 16, 26, 0.5)', paddingTop: '100px', bottom: '0'}}>
                    <Login/>
                </div>*/}


                <Label>
                    {/*<header>
                        <h4>
                            Generate Image
                        </h4>
                    </header>*/}
                    <Msg type='info'>
                        Type something and let AI generate a picture of what you type!
                    </Msg>
                </Label>



                {responseData.success && imageGenProgress.progress_status !='finished' && <>

                    <article>
                        {/*<span className='italic font12'>AI is generating a pic of</span><br/>*/}
                        {responseData.data.text}
                    </article>

                    <Label>
                        <Button className='width100' template='default-highlight'>Processing..</Button>
                    </Label>


                </> || <>

                    <Label>
                        <TextBox className='width100' placeholder='Type ANYTHING...' name='text' onChange={onChange} value={formObj.text}/>
                    </Label>

                    <Label>
                        <Button className='width100' template='default-highlight' onClick={onSubmit}>Generate Image</Button>
                        {AppContextState.profile.loggedIn && <>
                            <div className='font12 textalign-left'>This will cost: <span className='color-white'>{tokenCost.resolution_token_cost * tokenCost.iterations_token_cost}</span> tokens {tokenCost.resolution_token_cost * tokenCost.iterations_token_cost > 2 ? <span className='font-thin'>(Resolution X Iterations)</span> : ''} </div>
                        </>}
                    </Label>

                </>}





                {requestStatus == RequestStatusEnum.LOADING &&
                <Label>
                    <Msg type='loading'>Loading...</Msg>
                </Label>
                }


                {requestStatus == RequestStatusEnum.DONE &&
                    <>
                        {responseData.success && imageGenProgress.progress_status !='finished' &&
                            <>
                                <Msg type='loading'>
                                    {responseData.details}
                                    <br/>
                                    {imageGenProgress.current_iterations > 0 &&
                                        <span className={'italic font12'}>
                                            {imageGenProgress.current_iterations}/{imageGenProgress.max_iterations} iterations
                                        </span>
                                    }
                                </Msg>

                            </>
                        }



                        {imageGenProgress.progress_status == 'finished' &&
                        <>
                            <Label>
                                <ApiMsg requestStatus={getImgRequestStatus} responseData={getImgResponseData}/>
                            </Label>
                        </>
                        }


                        {!responseData.success && responseData.details != 'idle' &&
                        <>
                            <Label>
                                <ApiMsg requestStatus={requestStatus} responseData={responseData}/>
                            </Label>
                        </>
                        }


                        {imageGenProgress.image_url && <>
                            <Label>
                                <FormBoxImage src={imageGenProgress.image_url}/>
                            </Label>
                        </>}


                        {imageGenProgress.progress_status == 'finished' &&
                        <>
                            <article>
                                {responseData.data.text}
                            </article>
                        </>
                        }
                    </>
                }



                {!(responseData.success && imageGenProgress.progress_status !='finished') && <>
                    {'create_options' in AppContextState.site_data && <>

                        <Label>
                            <header>
                                <h3 className='textalign-left'>
                                    Resolution {tokenCost.resolution_token_cost>1 ? <span className='font12'>(cost: {tokenCost.resolution_token_cost} tokens)</span> : '' }
                                </h3>
                            </header>
                            <LinearRadioBox callback={onChangeRadio} name='resolution' options={AppContextState.site_data.create_options.resolution}/>
                            {/*[{'label':'350x350', 'value':'350x350', 'token_cost':1}, {'label':'825x575', 'value':'825x575', 'token_cost':2}]AppContextState.profile.site_data.create_options.iterations*/}

                            <header>
                                <h3 className='textalign-left'>
                                    Render Iterations {tokenCost.iterations_token_cost>1 ? <span className='font12'>(cost: {tokenCost.iterations_token_cost} tokens)</span> : '' }
                                </h3>
                            </header>
                            <LinearRadioBox callback={onChangeRadio} name='iterations' options={AppContextState.site_data.create_options.iterations}/>
                            {/*[{'label':'200', 'value':'200', 'token_cost':1}, {'label':'400', 'value':'400', 'token_cost':2}, {'label':'800', 'value':'800', 'token_cost':4}, {'label':'1200', 'value':'1200', 'token_cost':6}]*/}
                        </Label>

                    </>}

                </>}

            </FormBox>

            <div className={'clear'}></div>



            {/*{AppContextState.profile.loggedIn ? <>*/}

            {/*    <ImageFeed personal={true}/>*/}

            {/*</> : <>*/}

            {/*    <ImageFeed/>*/}

            {/*</> }*/}



            {!AppContextState.profile.loggedIn && <>
                <ImageFeed/>
            </>}


            {AppContextState.profile.loggedIn && <>

                <FormBox className={'mobile-hide'} title='Earn Tokens' style={{position: 'sticky', top: '30px'}}>
                    <Label>
                        <Msg type='info'>Share your profile to earn tokens!</Msg>
                    </Label>
                    <Label>
                        <TextBox readOnly={true} className='width100' placeholder='http://URL.COM/' name='text' value='http://URL.COM/'/>
                    </Label>
                </FormBox>

            </>}



            <SideComponent>
                {/*<header className='textalign-center'>
                    <h2>Community Creations</h2>
                </header>*/}


                <ImageFeed sideComponent={true} personal={true}/>

            </SideComponent>


        </Body>


    </>)
}

export default Create;