import React, {useContext, useState} from 'react';
import FormBox from "../../../page-utils/FormBox";
import Label from "../../../page-utils/Label";
import {Msg} from "../../../page-utils";
import {TextBox} from "../../../components/TextBoxes";
import AppContext from "../../../../context/AppContext";
import {useParams} from "react-router-dom";
import CheckBox from "../../../components/CheckBoxes/CheckBox";
import useFetchApi, {RequestStatusEnum} from "../../../../custom-hooks/useFetchApi";
import Body, {BodyTemplates} from "../../Body";
import SideComponentBox from "../../../page-utils/SideComponentBox";
import {Button} from "../../../components/buttons";
import SideComponent from "../../SideComponent";

function ManageRate() {
    const {state, dispatch} = useContext(AppContext);

    const { groupId, rateId } = useParams();



    const [requestData, setRequestData] = useState({group_id:groupId, rate_id:rateId})
    const {requestStatus, responseData} = useFetchApi('get_ad_rate_for_business', requestData);




    return (<>



        <FormBox title={'Manage Rate'}>




            {requestStatus == RequestStatusEnum.LOADING &&
            <Label>
                <Msg type='loading'>Loading...</Msg>
            </Label>
            }


            {requestStatus == RequestStatusEnum.DONE &&
                <>
                    <Msg type='info'>
                        Send link below to influencer to schedule an ad with them
                    </Msg>


                    <Label>
                        <TextBox template={'link'} className='width100' value={`${state.PUBLIC_PATH}schedule/${rateId}/${responseData.data.rate_id_hash}`}/>
                    </Label>

                    <Label>
                        <CheckBox on={false} title={'Pause Ads'} onCallBack={()=>{}} offCallBack={()=>{}}>Pause ads with this influencer and ad group?</CheckBox>
                    </Label>
                </>

            }


        </FormBox>


        <SideComponent>
            <SideComponentBox title={'Ad Rates'}>
                <header>
                    <h2>$450</h2>
                </header>
                <label>
                    <Button className={'width100'}>Renegotiate Ad Price</Button>
                </label>

            </SideComponentBox>
        </SideComponent>


    </>);
}


export default ManageRate;