import React from 'react';
import {Button} from "../components/buttons";
import Label from "./Label";

function PaginatorNavigator({page, setPage, totalPages}) {


    if(!totalPages){
        return (<></>);
    }


    return (<>

        <Label className='paginator-inputs'>


            {page >0 &&

            <Button template='paginator' onClick={()=>{ setPage(page-1)}}>Prev</Button>

            }



            {((page==0 && totalPages==1) || page < totalPages) &&

            <Button template='paginator' onClick={()=>{ setPage(page+1)}}>{page==0 ? 'Load More' : 'Next'}</Button>

            }
        </Label>
    </>);
}


export default PaginatorNavigator;