import React from 'react';
import FormBox from "./FormBox";

function SideComponentBox(props) {
    return <FormBox sideComponent={true} {...props}/>

    /*const {border, className, title, children, ...otherProps} = props;
    return (<>
        <div  className={'form-box side-component-box ' + className} {...otherProps}>
            {title && (
                <header>
                    <h1>{title}</h1>
                </header>

            )}

            <div className={'form-box-content ' + (border===false ? 'border-none' : '' ) }>
                {children}
            </div>

            <div className='clear'></div>
        </div>
    </>);*/
}


export default SideComponentBox;