import React from "react";


function Footer(){
    return (<>
        <footer className='footer page-grid-item page-grid-item-footer'>
            <div className='ocean-wrap'>
                <div className="ocean" >
                    <div className="wave"></div>
                    <div className="wave"></div>
                </div>
            </div>


        </footer>
    </>)
}


export default Footer;