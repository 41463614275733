import {useState, useEffect, useCallback, useContext} from 'react';
import useFetch from "./useFetch";
import AppContext from "../context/AppContext";
export {RequestStatusEnum as RequestStatusEnum} from './useFetch';


function useFetchApi (action, requestData, requestDataType) {

    const {state, dispatch} = useContext(AppContext);


    return useFetch(`${state.API.url}?a=${action}`, requestData, requestDataType);
};


export default useFetchApi;
