import {bindReporter} from "web-vitals/dist/lib/bindReporter";

const helpers = {
    helper1: function(){
        alert(5);
    },
    helper2: function(param1){

    },
    helper3: function(param1, param2){

    },


    set_cookie: function(name,value,days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + value + expires + "; path=/";
    },

    get_cookie: function(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    },


    friendly_generate_text: function(text, max_length){
        max_length = max_length || 20;
        if(text.length>max_length){
            return text.substr(0, max_length) + '...';
        }
        return text;
    },


    get_between_hours_timestamps: function(min_hour_timestamp, max_hour_timestamp, schedule_start_timestamp){
        let allowed_timestamps = [];


        let post_hours_leak_unto_next_day = false;

        let min_hours_date = new Date(min_hour_timestamp * 1000);
        let min_hours = min_hours_date.getHours();

        let max_hours_date = new Date(max_hour_timestamp * 1000);
        let max_hours = max_hours_date.getHours();

        /*if(min_hours > max_hours){
            post_hours_leak_unto_next_day = true;
        } else {
            let total_hours = max_hours - min_hours;


            for(let i=0;i<=total_hours;i++){


                let new_allowed_timestamp_date = new Date(schedule_start_timestamp);
                new_allowed_timestamp_date.setHours(min_hours + i);

                //allowed_timestamps.push(new_allowed_timestamp_date.toLocaleDateString() + ' ' + new_allowed_timestamp_date.toLocaleTimeString());
                allowed_timestamps.push(new_allowed_timestamp_date.getTime());
            }

        }*/



        let i = 0;
        while(true){
            let new_allowed_timestamp_date = new Date(schedule_start_timestamp);
            new_allowed_timestamp_date.setHours(min_hours + i);

            allowed_timestamps.push(new_allowed_timestamp_date.getTime());

            i++;

            //alert(new_allowed_timestamp_date.getHours() + ' - ' + max_hours_date.getHours())

            if(new_allowed_timestamp_date.getHours() == max_hours_date.getHours()){
                break;
            }

            //Fallback incase code is rendered server side
            if(i>25){
                break;
            }
        }



        //alert(JSON.stringify(allowed_timestamps));

        return allowed_timestamps;
    },



    convert_24h_to_12h: function(hour_str){
        //it is pm if hours from 12 onwards
        let suffix = (hour_str >= 12)? 'pm' : 'am';

        //only -12 from hours if it is greater than 12 (if not back at mid night)
        hour_str= (hour_str > 12)? hour_str -12 : hour_str;

        //if 00 then it is 12 am
        hour_str = (hour_str == '00')? 12 : hour_str;

        return hour_str + suffix;
    },



    getRowColor: function(colorIndex, scheme){
        scheme = scheme ||  'rows';

        let rowColors = [];

        switch(scheme){
            case 'rows':
                rowColors = [
                    '#f9ce70',
                    '#f8a288',
                    '#bed3ff',
                    '#2cd8af',

                ];
                break;
            case 'dates':
                rowColors = [
                    '#befcff',//'#bed3ff',
                    '#5eade4',//'#d5beff',

                ];
                break;
        }


        if(colorIndex>=rowColors.length-1){
            let indexMultiples = Math.floor(colorIndex / (rowColors.length));
            colorIndex = (colorIndex - (indexMultiples * (rowColors.length)));
        }


        return {color:rowColors[colorIndex]};

    },

    /*getRowColorDates: function(colorIndex){


        const rowColors = [
            '#bed3ff',
            '#d5beff',

        ];


        if(colorIndex>=rowColors.length-1){
            let indexMultiples = Math.floor(colorIndex / (rowColors.length));
            colorIndex = (colorIndex - (indexMultiples * (rowColors.length)));
        }


        return {color:rowColors[colorIndex]};

    }*/

}

export default helpers;