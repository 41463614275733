import React, {useState, useEffect, useContext} from "react";
import Body, {BodyTemplates} from "./page-sections/Body";
import AppContext from "../context/AppContext";
import ImageFeed from "./page-sections/Panel/ImageFeed";
import Label from "./page-utils/Label";
import {Msg} from "./page-utils";




function Recent() {


    const {state: AppContextState, dispatch: AppContextDispatch} = useContext(AppContext);


    return (<>

        <Body template={AppContextState.profile.loggedIn ? BodyTemplates.MAIN_MENU_SIDE : BodyTemplates.MAIN}>

            {!AppContextState.profile.loggedIn && <>
                <div className='width50 mobile-width90 margin-auto'>
                    <Msg type='info'>
                        All of these images were generated by AI with the help of community members!
                    </Msg>
                </div>
            </>}


            <ImageFeed/>

        </Body>


    </>)
}

export default Recent;