import React, {useContext, useState} from "react";
import {Button, NavButton} from "../components/buttons";
import AppContext from "../../context/AppContext";
import useFetchApi from "../../custom-hooks/useFetchApi";
import {Link, useRouteMatch} from "react-router-dom";
import {Msg} from "../page-utils";
import Helpers from "../../helpers";


function Head() {

    const { url, path } = useRouteMatch();


    const {state, dispatch} = useContext(AppContext);

    const [tryNowClicked, setTryNowClicked] = useState(Helpers.get_cookie('try_now'));


    function setTryNowCookie(){
        Helpers.set_cookie('try_now', true, 90);
        setTryNowClicked(true)
        return true;
    }


    return (<>
            <section className='head page-grid-item-head'>
                <div className='nav-wrap'>
                    <div className='nav-section-logo'>
                        <Link to='/'>
                            <div className='logo-wrap mobile-hide'>
                                <div className='logo'></div>
                            </div>
                        </Link>
                    </div>
                    <div className='nav-section-buttons mobile-width100'>

                        <div className='pull-right mobile-width30'>

                            <NavButton template='nav-button-head' className='mobile-pull-right' to='/create' onClick={()=>{return path.indexOf('/create')!==-1 ? setTryNowClicked(false) : false}}>CREATE</NavButton>

                        </div>


                        <div className='pull-right mobile-width70'>
                            {state.profile.loggedIn ?

                                <>

                                    {/*<NavButton to='logout' onClick={() => dispatch({type: 'SHOW_POPUPSCREEN', payload:'my LOGIN CONTENT'})}>LOGOUT</NavButton>
*/}
                                    <NavButton to='/logout'>LOGOUT</NavButton>

                                    <NavButton to='/recent'>TOP CREATIONS</NavButton>

                                </>

                            :
                            <>

                                <NavButton to='/login'>LOGIN</NavButton>

                                <NavButton to='/recent'>TOP CREATIONS</NavButton>


                            </>

                            }



                        </div>


                    </div>
                </div>
                <div className='clear'></div>

            </section>



            {/*{!state.profile.loggedIn && !tryNowClicked && path.indexOf('/login')===-1 && <>

                <section className='nav-mobile-wrap'>
                    <div className='nav-mobile-grid'>
                        <div className='nav-mobile-item nav-mobile-tutorial'>
                            <Msg type='info' className='color-white'>Type ANYTHING and let AI create an image of it!</Msg>
                        </div>
                        <div className='nav-mobile-item nav-mobile-try-now textalign-center' style={{position: 'relative'}}>
                            <NavButton className='margin-auto font16' template='default-highlight' to='/create' onClick={()=>{return path.indexOf('/create')!==-1  ? setTryNowCookie() : false}}>{path.indexOf('/create')!==-1 ? 'Got it!' : 'Try now'}</NavButton>
                            style={{position: 'absolute', minWidth: '80%'}}
                        </div>
                    </div>

                </section>

            </>}*/}





        </>
    )
}


export default Head;