import React from "react";
import {useSpring, animated} from "react-spring";


function Msg(props){
    const {type, className, children, ...otherProps} = props;

    const styles = useSpring({opacity: 1, from: {opacity: 0}})

    return (<>
        {children &&
            <animated.div style={styles} className={`msg ${type} ${className || ''}`}>

                {type == 'loading' &&

                <div className="loadingio-spinner">
                    <div className="ldio">
                        <div>
                            <div>
                                <div>
                                    <div></div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                }




                {children}
            </animated.div>
        }
    </>)
}

export default Msg;