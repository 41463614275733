import React from 'react';


function TextBox({template, type, ...props}) {
    let {className, ...otherProps} = props;
    template = template || 'default';
    let inputClass = '';
    let readOnly = false;

    switch (template){
        case 'link':
            readOnly = true;
            otherProps['onFocus'] = selectAll;
            otherProps['onClick'] = selectAll;
        case 'default':
        case 'login':
        case 'link':
            inputClass = 'text-login';
            break;


    }


    function selectAll(e){
        e.target.select();
    }


    return (
        <input readOnly={readOnly} autoComplete="off" type={type || 'text'} className={inputClass + ' ' + className} {...otherProps}/>
    );
}


export default TextBox;