import React from "react";
import FormBox from "./FormBox";
import FormBoxImage from "./FormBoxImage";
import Label from "./Label";
import SideComponentBox from "./SideComponentBox";




function ImageFeedItemInner(props){
    const {image_id, user, timestamp, border, className, title, children, sideComponent, ...otherProps} = props;



    return (<>




        <FormBoxImage src={`https://cdn.artgenerator.studio/generated_images/${image_id}.png`}/>

        <Label>
            <div className='form-box-image-panel'>
                <div className='panel-author'>
                    <span className='author-user'>@{user}</span>
                </div>
                <div className='panel-timestamp'>
                    {timestamp}
                </div>
                <div className='panel-social'>
                    <div className='action'>
                        <div className='action-like icon icon-like-hollow'></div>
                    </div>
                </div>
            </div>
        </Label>


    </>);

}



function ImageFeedItem(props){
    const {title, sideComponent, ...otherProps} = props;


    return (<>

        {sideComponent ? <>

            <SideComponentBox padding={false} border={false} title={title}>
                <ImageFeedItemInner {...props}/>
            </SideComponentBox>

        </> : <>

            <FormBox padding={false} border={false} title={title}>
                <ImageFeedItemInner {...props}/>
            </FormBox>

        </>}






    </>)


}


export default ImageFeedItem;