import React from 'react';
import {Link, useLocation} from 'react-router-dom'


/*Nav bar for current nested location on the site*/
function PageMap(props) {

    const location = useLocation();

    //alert(location.pathname); /panel/manage/19/rates/5

    //<PageMap navLocations='Panel:panel/Manage Ads:manage/Manage Rates' />


    //Parse path string that we made our self and passed to component


    let nestedPagesParts = props.navLocations.split('/');

    let nestedPages = [];


    for(let i = 0;i<=nestedPagesParts.length-1;i++){

        let friendly_path_name = nestedPagesParts[i];
        if(nestedPagesParts[i].indexOf(':')!==-1){
            friendly_path_name = nestedPagesParts[i].split(':')[0];
        }

        let path_location_key = '';//location; //Refresh page with current location if no path specified in our nav string we made and passed.
        let path_location_full = '/';//location;
        if(nestedPagesParts[i].indexOf(':')!==-1){
            path_location_key = nestedPagesParts[i].split(':')[1];


            //Build route path string to nav to if location elem clicked
            let current_route_parts = location.pathname.split('/');

            current_route:
                for(let j = 0;j<=current_route_parts.length-1;j++){

                    //alert(path_location_key + ' : ' + current_route_parts[j]);

                    if(!current_route_parts[j]){
                        //Skip empty path locations
                        continue;
                    }




                    path_location_full += current_route_parts[j] + (j == current_route_parts.length-1 ? '' : '/') //Add trailing slash if not last elem



                    //TEMPORARY FIX, a better way to check this would be to check if the parameter next to it is a url variable from react router rather than a static path name
                    //If current url route path part has an integer in the next part, then add the integer to the full route path
                    if(current_route_parts[j+1] && /\d/.test(current_route_parts[j+1])){
                        path_location_full += current_route_parts[j+1];
                    }


                    if(path_location_key==current_route_parts[j]){
                        break current_route;
                    }

                }

        } else {
            path_location_full = location.pathname;
        }


        path_location_full = path_location_full.replace(/\/$/, "");

        //alert(friendly_path_name + ' : ' + path_location_full);
        nestedPages.push({'friendly_name':friendly_path_name, 'path_location_full':path_location_full})




    }

    return (<>



        <div className='page-map-wrap'>
            <div className='page-map-inner'>


                {nestedPages.map((nestedPage, index)=>{

                    let isSelectedPage = index==nestedPages.length-1;

                    return (<div  key={index}>

                        <Link to={nestedPage.path_location_full}>
                            <div className={`page-map-item ${isSelectedPage ? 'selected' : ''}`}>
                            <span>
                                {nestedPage.friendly_name}

                            </span>

                                {!isSelectedPage &&
                                <>
                                    <div className='nav-arrow-icon'></div>
                                </>
                                }

                            </div>
                        </Link>
                        {/*{nestedPage.friendly_name} : {nestedPage.path_location_full} <br/>*/}

                    </div>)

                })}

                <div className='clear'></div>

            </div>

            <div className='clear'></div>

        </div>
    </>);
}


export default PageMap;