import React, {useState, useReducer, useEffect, useContext} from 'react';
import useFetchApi, {RequestStatusEnum} from "../../../custom-hooks/useFetchApi";
import Body, {BodyTemplates} from "../Body";
import FormBox from "../../page-utils/FormBox";
import {Msg} from "../../page-utils";
import Label from "../../page-utils/Label";
import {Button} from "../../components/buttons";
import {TextBox} from "../../components/TextBoxes";
import ApiMsg from "../../page-utils/ApiMsg";
import usePaginator from "../../../custom-hooks/usePaginator";
import PaginatorNavigator from "../../page-utils/PaginatorNavigator";
import {Link, useParams, useRouteMatch} from "react-router-dom";
import {RequestDataTypeEnum} from "../../../custom-hooks/useFetch";
import AppContext from "../../../context/AppContext";




function ImgUploader({step, setStep, ...props}) {

    const { groupId } = useParams();

    const [requestData, setRequestData] = useState(false)
    const {requestStatus, responseData} = useFetchApi('upload_new_ad_variant', requestData, RequestDataTypeEnum.FILE_UPLOAD);



    const [selectedFiles, setSelectedFiles] = useState([])



    function fileSelect(e){


        setSelectedFiles(Array.from(e.target.files))


        /*for (let key of tmpFormData.entries()) {
            console.log(key[0] + ', ' + key[1]);
        }*/
        //setRequestData(tmpFormData)

        /*selectedFiles.forEach((file, i) => {
            //formData.append(i, file)
        })*/


        //alert(JSON.stringify(formData));

    }

    function uploadFiles(){



        if(selectedFiles.length){
            const formData = new FormData()
            formData.append(parseInt(groupId).toString(), selectedFiles[0])
            /*selectedFiles.forEach((file, i) => {
                formData.append(i, file, groupId.toString())
            })*/

            setRequestData(formData)
        } else {
            alert('Please select files to upload')
        }
    }


    useEffect(function(){

        if(responseData.success){
            setStep(0);
        }
    }, [responseData]);

    return (<>

        <Label>
            <div className='upload-wrap'>
                <input type="file" onChange = {fileSelect} />

                <Button onClick={uploadFiles}>Upload</Button>
            </div>
        </Label>


        <ApiMsg requestStatus={requestStatus} responseData={responseData}/>

    </>);
};




function ShowAzVariants({step, setStep, ...props}){
    const { url, path } = useRouteMatch();

    const { groupId } = useParams();


    const {requestStatus, responseData, page, setPage, totalPages, rowColors} = usePaginator('get_ad_variants', 0, {'group_id':groupId});


    const {state: AppContextState, dispatch: AppContextDispatch} = useContext(AppContext);


    return (<>
        <Msg type='info'>
            Create and manage your ads for this group
        </Msg>




        <Label>
            {requestStatus == RequestStatusEnum.LOADING &&
            <Label>
                <Msg type='loading'>Loading...</Msg>
            </Label>
            }



            {requestStatus == RequestStatusEnum.DONE &&


            (responseData.data.rows.length>0 ?


                    (<>
                        {responseData.data.rows.map((item, i)=>{

                            return (
                                <Link to={`${url}/${i}`}>
                                    <Label key={item.id} style={rowColors[i]} className='width100 rows rows-img-grid'>
                                        <div className='row-title'>
                                            {item.variant_title}
                                        </div>

                                        <div className='row-stats'>
                                            Scheduled: 5 <br/>
                                            Currently Running: 20<br/>
                                            Total Past Runs: 30
                                        </div>

                                        <div className='row-img-wrap'>
                                            <img className='row-img' src={AppContextState.API.url + `user_storage/images/${item.id}.png`}/>
                                            <div className='cover'></div>
                                        </div>




                                    </Label>
                                </Link>



                            )

                        })}


                        <PaginatorNavigator page={page} setPage={setPage} totalPages={totalPages}/>
                    </>)

                    :

                    <article>
                        No ad groups created yet..
                    </article>

            )

            }





        </Label>
        <Label >
            {/*<Button template='default' icon='plus' onClick={()=>{dispatch({type: 'SET_STEP', payload:reducerState.step + 1})}}>Create Group</Button>*/}
            <Button template='default' icon='plus' onClick={()=>{setStep(step+1)}}>Upload New Ad</Button>
        </Label>
    </>);
};




function AzVariants(props) {
    const [step, setStep] = useState(0);





    return (<>





        <FormBox title={`Ads for `}>



            {step == 0 &&

            (<>

                <ShowAzVariants step={step} setStep={setStep}/>
            </>)


            }


            {step == 1 &&

            (<>

                <ImgUploader step={step} setStep={setStep} />

            </>)


            }

        </FormBox>





    </>);
};


export default AzVariants;