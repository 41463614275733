import React, {useMemo, useReducer} from "react";
import Footer from "./Footer";
import NavMenu from "./NavMenu";
import Head from "./Head";
import BodyContext from "../../context/BodyContext";
import SideComponentBox from "../page-utils/SideComponentBox";




const defaultState = {
    sideComponents: [],
};


function reducer(state, action){
    //alert(JSON.stringify({...state, sideComponent:action.payload}))

    switch (action.type){
        case 'ADD_SIDE_COMPONENT':

            return {...state, sideComponents:[...state.sideComponents, action.payload]}
            break;

        case 'CLEAR_SIDE_COMPONENTS':

            return {...state, sideComponents: []}
            break;

    }
    throw new Error('no matching action type');
}


export const BodyTemplates = {
    MAIN:1,
    MAIN_MENU:2,
    MAIN_MENU_SIDE:3
}

function Body({template, SideComponent, children, ...props}){

    const [bodyState, bodyDispatch] = useReducer(reducer, defaultState);


    const contextValue = useMemo(() => {
        return { bodyState, bodyDispatch };
    }, [bodyState, bodyDispatch]);




    let gridClass = '';


    let sideComponentEnabled = false;
    let panelComponentEnabled = false;

    switch (template){
        case BodyTemplates.MAIN:
            gridClass = 'page-grid-main'
            break;
        case BodyTemplates.MAIN_MENU:
            gridClass = 'page-grid-main-menu'
            panelComponentEnabled = true;
            break;
        case BodyTemplates.MAIN_MENU_SIDE:
            gridClass = 'page-grid-main-menu-side'
            panelComponentEnabled = true;
            sideComponentEnabled = true;
            break;

    }

    return (<>
        <BodyContext.Provider value={contextValue}>
            <div className={`page-grid-wrap ${gridClass}`}>

                <Head/>

                {panelComponentEnabled &&
                <>
                    <NavMenu/>
                </>
                }



                <main className='page-grid-item page-grid-item-main'>


                    {template == BodyTemplates.MAIN && (

                        <>

                            <div className={'logo-wrap mobile'}>
                                <div className='logo'></div>
                            </div>

                        </>

                    )}

                    {children}
                </main>


                {sideComponentEnabled &&

                /*<aside className='page-grid-item page-grid-item-main-side'>{SideComponent}</aside>*/
                <aside className='page-grid-item page-grid-item-main-side'>
                    {/*<SideComponent renderChildrenHere={true}>{bodyState.sideComponent.children}</SideComponent>*/}
                    {/*<SideComponent></SideComponent>*/}
                    {bodyState.sideComponents.map((sideComponent, index)=>{

                        return <div key={index}>{sideComponent.children}</div>

                    })}
                </aside>

                }


                <Footer/>
            </div>
        </BodyContext.Provider>

    </>)
}


export default Body;