import React, {useState, useEffect, useCallback, useContext} from 'react';
import useFetchApi, {RequestStatusEnum} from "./useFetchApi";
import {Msg} from "../pages/page-utils";
import Label from "../pages/page-utils/Label";
import {Button} from "../pages/components/buttons";
import helpers from "../helpers";



//Rails for pagination

function usePaginator(action, initPage, ...otherRequestData) {

    const [page, setPage] = useState(initPage);


    const [requestData, setRequestData] = useState(false);
    const {requestStatus, responseData} = useFetchApi(action, requestData);

    const [rowColors, setRowColors] = useState([]);
    const [totalPages, setTotalPages] = useState(0);



    useEffect(()=>{

        if(typeof (otherRequestData) != 'undefined'){
            //If there is other data to filter pages by, pass it

            let tmpReqData = {}
            let newReqData = Object.assign(tmpReqData, ...otherRequestData);
            newReqData['page'] = page;

            setRequestData(newReqData)

        } else {

            setRequestData({ page:page})
        }

    }, [page])




    /*function getRowColor(colorIndex){
        const rowColors = [
            '#f9ce70',
            '#f8a288',
            '#bed3ff',
            '#2cd8af',

        ];


        if(colorIndex>=rowColors.length-1){
            let indexMultiples = Math.floor(colorIndex / (rowColors.length));
            colorIndex = (colorIndex - (indexMultiples * (rowColors.length)));
        }


        return {color:rowColors[colorIndex]};

    }*/



    useEffect(function(){
        if(requestStatus == RequestStatusEnum.DONE && responseData.success){
            let tmpRowColors = [];
            responseData.data.rows.map((item, i)=>{
                tmpRowColors.push(helpers.getRowColor(i))
            })

            setRowColors(tmpRowColors);

            setTotalPages(responseData.data.total_pages);

        }

    }, [requestStatus]);




    return { requestStatus, responseData, page, setPage, totalPages, rowColors };

};


export default usePaginator;