import React, {useState, useReducer, useEffect, useContext, useRef} from 'react';
import useFetchApi, {RequestStatusEnum} from "../../../custom-hooks/useFetchApi";
import Body, {BodyTemplates} from "../Body";
import FormBox from "../../page-utils/FormBox";
import {Msg} from "../../page-utils";
import Label from "../../page-utils/Label";
import {Button} from "../../components/buttons";
import {TextBox} from "../../components/TextBoxes";
import ApiMsg from "../../page-utils/ApiMsg";
import usePaginator from "../../../custom-hooks/usePaginator";
import PaginatorNavigator from "../../page-utils/PaginatorNavigator";
import {Link, useRouteMatch} from "react-router-dom";
import SideComponent from "../SideComponent";
import AppContext from "../../../context/AppContext";
import BodyContext from "../../../context/BodyContext";
import SideComponentBox from "../../page-utils/SideComponentBox";
import {
    FlexibleXYPlot,
    HorizontalGridLines, LineSeries,
    VerticalBarSeries,
    VerticalGridLines,
    XAxis,
    XYPlot,
    YAxis,
    Crosshair, DiscreteColorLegend
} from "react-vis";
import Helpers from "../../../helpers";



/*const defaultState = {
    step: 0
}


function reducer(state, action){
    switch (action.type){
        case 'SET_STEP':
            return {...state, step: {...action.payload}}
            break
    }
    throw new Error('no matching action type');
}*/



// function Overview({step, setStep, ...props}){
//     /*const [reducerState, dispatch] = useReducer(reducer, defaultState);*/
//     const { url, path } = useRouteMatch();
//
//
//     const {requestStatus, responseData, page} = usePaginator('get_overview', 0);
//
//
//
//     const data = [
//         {x: 0, y: 8},
//         {x: 1, y: 5},
//         {x: 2, y: 4},
//         {x: 3, y: 9},
//         {x: 4, y: 1},
//         {x: 5, y: 7},
//         {x: 6, y: 6},
//         {x: 7, y: 3},
//         {x: 8, y: 2},
//         {x: 9, y: 7},
//         {x: 10, y: 8},
//         {x: 11, y: 5},
//         {x: 12, y: 4},
//         {x: 13, y: 9},
//         {x: 14, y: 1},
//         {x: 15, y: 7},
//         {x: 16, y: 6},
//         {x: 17, y: 3},
//         {x: 18, y: 2},
//         {x: 19, y: 0}
//     ];
//
//
//     return (<>
//         <Msg type='info'>
//             Overview
//         </Msg>
//
//         <Label>
//             {requestStatus == RequestStatusEnum.LOADING &&
//                 <Label>
//                     <Msg type='loading'>Loading...</Msg>
//                 </Label>
//             }
//
//
//
//             {requestStatus == RequestStatusEnum.DONE &&
//
//
//                 (responseData.data.length>0 ?
//
//
//                         (<>
//
//
//                             <XYPlot height={200} style={{width:'100%'}}>
//                                 <LineSeries data={data} />
//                             </XYPlot>
//
//                         </>)
//
//                     :
//
//                     <article>
//                         No data...
//                     </article>
//
//                 )
//
//             }
//
//
//
//
//
//         </Label>
//         <Label >
//             {/*<Button template='default' icon='plus' onClick={()=>{dispatch({type: 'SET_STEP', payload:reducerState.step + 1})}}>Create Group</Button>*/}
//             <Button template='default' icon='plus' onClick={()=>{setStep(step+1)}}>Create Group</Button>
//         </Label>
//     </>);
// };


function CreateAzGroup({step, setStep, ...props}){


    const [requestData, setRequestData] = useState({})
    const {requestStatus, responseData} = useFetchApi('new_ad_group', requestData);


    const [formObj, setFormObj] = useState({group_name:''});


    function onChange(e){
        setFormObj({...formObj, [e.target.name]:e.target.value });

    }

    function onSubmit(){
        setRequestData(formObj);
    }

    useEffect(function(){

        if(responseData.success){
            setStep(0);
        }
    }, [responseData]);

    return (<>
        <Msg type='info'>
            Enter the name of this group of ads...
        </Msg>

        <Label>
            <TextBox className='width100' placeholder='Name of group' name='group_name' onChange={onChange} value={formObj.group_name}/>
        </Label>
        <Label className='input-grid'>
            <Button template='default' onClick={onSubmit} icon='plus'>Save New Group</Button>
            <Button template='default' onClick={()=>{setStep(step-1)}}>Cancel</Button>
        </Label>

        <ApiMsg requestStatus={requestStatus} responseData={responseData}/>
    </>);
};



function Overview() {
    const [step, setStep] = useState(0);

    const [crosshairValues, setCrosshairValues] = useState([]);
    const chartRef = useRef(null);




    const [formObj, setFormObj] = useState({'metric':'hourly_unique_users', 'selected_date':getFormattedDate(), 'comparison_date':getFormattedDate(1)})


    const [requestData, setRequestData] = useState(formObj)
    const {requestStatus, responseData} = useFetchApi('get_overview', requestData);

    const [statsRequestData, setStatsRequestData] = useState(formObj)
    const {requestStatus: statsRequestStatus, responseData: statsResponseData} = useFetchApi('get_overview_stats', statsRequestData);

    /*const responseData = {'title':'Total Unique Users', 'item_labels':['Selected', 'Comparison'], 'data':[
            [{x: '1-1-2023', y: 3}, {x: '1-2-2023', y: 7}],
            [{x: '1-1-2023', y: 80}, {x: '1-2-2023', y: 1}]
        ]};*/



    function onChange(e){


        if(e.target.name == 'selected_date' ){

            let new_comparison_date =  formObj.comparison_date;
            if((getDaysAgo(formObj.comparison_date) == (getDaysAgo(formObj.selected_date)-1) )){
                new_comparison_date = getFormattedDate(getDaysAgo(e.target.value));
            }
            setFormObj({...formObj, 'comparison_date':new_comparison_date, [e.target.name]:e.target.value });
        } else if(e.target.name == 'comparison_date') {
            setFormObj({...formObj, [e.target.name]:e.target.value });
        }


    }

    useEffect(function(){

        setRequestData(formObj);
        setStatsRequestData(formObj);

    },[formObj]);


    const handleMouseLeave = () => {
        setCrosshairValues(null);
    };

    const handleNearestX = (value, { index }) => {
        setCrosshairValues(responseData.data.data.map(d => d[index]))

    };


    //const {bodyState, bodyDispatch} = useContext(BodyContext);

    useEffect(function(){


        //bodyDispatch({type:'SET_SIDE_COMPONENT', payload:'test me'})
    }, [])

    //dispatch({type:'SHOW_POPUPSCREEN', payload:<>Test MEEEEE</>})

    /*const data = [
            [{x: 0, y: 3}, {x: 1, y: 9}, {x: 2, y: 3}, {x: 3, y: 1}, {x: 4, y: 8}, {x: 5, y: 4}, {x: 6, y: 2}, {x: 7, y: 1}, {x: 8, y: 0}, {x: 9, y: 6}, {x: 10, y: 3}, {x: 11, y: 2}, {x: 12, y: 6}, {x: 13, y: 9}, {x: 14, y: 5}, {x: 15, y: 4}, {x: 16, y: 7}, {x: 17, y: 2}, {x: 18, y: 7}, {x: 19, y: 8}],
            [{x: 0, y: 80}, {x: 1, y: 5}, {x: 2, y: 4}, {x: 3, y: 9}, {x: 4, y: 1}, {x: 5, y: 7}, {x: 6, y: 6}, {x: 7, y: 3}, {x: 8, y: 2}, {x: 9, y: 7}, {x: 10, y: 8}, {x: 11, y: 5}, {x: 12, y: 4}, {x: 13, y: 9}, {x: 14, y: 1}, {x: 15, y: 7}, {x: 16, y: 6}, {x: 17, y: 3}, {x: 18, y: 2}, {x: 19, y: 0}]
    ];*/

    // const data = [
    //     [{x: '1-1-2023', y: 3}, {x: '1-2-2023', y: 7}],
    //     [{x: '1-1-2023', y: 80}, {x: '1-2-2023', y: 1}]
    // ];

        //[[{"x": 0, "y": 658}, {"x": 1, "y": 400}, {"x": 2, "y": 233}, {"x": 3, "y": 150}, {"x": 4, "y": 97}, {"x": 5, "y": 156}, {"x": 6, "y": 458}, {"x": 7, "y": 662}, {"x": 8, "y": 995}, {"x": 9, "y": 1183}, {"x": 10, "y": 1466}, {"x": 11, "y": 1516}, {"x": 12, "y": 1668}, {"x": 13, "y": 1739}, {"x": 14, "y": 2063}, {"x": 15, "y": 1997}, {"x": 16, "y": 2069}, {"x": 17, "y": 2110}, {"x": 18, "y": 2029}, {"x": 0, "y": 0}, {"x": 0, "y": 0}, {"x": 0, "y": 0}, {"x": 0, "y": 0}, {"x": 0, "y": 0}], [{"x": 0, "y": 1306}, {"x": 1, "y": 777}, {"x": 2, "y": 413}, {"x": 3, "y": 219}, {"x": 4, "y": 146}, {"x": 5, "y": 155}, {"x": 6, "y": 265}, {"x": 7, "y": 528}, {"x": 8, "y": 810}, {"x": 9, "y": 1054}, {"x": 10, "y": 1325}, {"x": 11, "y": 1653}, {"x": 12, "y": 1965}, {"x": 13, "y": 2029}, {"x": 14, "y": 1914}, {"x": 15, "y": 2064}, {"x": 16, "y": 2241}, {"x": 17, "y": 2465}, {"x": 18, "y": 2678}, {"x": 19, "y": 2688}, {"x": 20, "y": 2616}, {"x": 21, "y": 2414}, {"x": 22, "y": 1664}, {"x": 23, "y": 1112}]]






    function build_item_labels(d){

        let titles = [];

        for(let i=0;i<=d.length-1;i++){
            titles.push({title: responseData.data.item_labels[i], value: d[i].y});
        }

        return titles;
        //[{title: 'data 1', value: d[0].y}, {title: 'data 2', value: d[1].y}]
    }

    function get_chart_color_by_index(index){
        return ['#1F77B4', '#1F77B455'][index];
    }


    function convertHourTo12HourFormat(hour) {
        if (hour === 0) {
            return 12 + ' AM';
        } else if (hour === 12) {
            return 12 + ' PM';
        } else if (hour < 12) {
            return hour + ' AM';
        } else {
            return (hour - 12) + ' PM';
        }
    }

    function formatNumber(number) {
        if (number >= 1000000) {
            return (number / 1000000).toFixed(1) + 'm';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(1) + 'k';
        } else {
            return number.toString();
        }
    }

    function getFormattedDate(days_ago = 0) {
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - days_ago);

        const year = yesterday.getFullYear();
        const month = String(yesterday.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(yesterday.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    function getDaysAgo(targetDate) {
        // Parse the target date and today's date as Date objects
        const targetDateTime = new Date(targetDate + 'T00:00:00Z');
        const today = new Date();

        // Calculate the time difference in milliseconds
        const timeDifference = today - targetDateTime;

        // Calculate the number of days by dividing milliseconds by milliseconds in a day
        const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        return daysAgo;
    }

    function updateSelectedDateOnClick(e){
        console.log(e.target.name)
        if(e.target.name=='date_go_back'){
            setFormObj({...formObj, selected_date: getFormattedDate(getDaysAgo(formObj.selected_date)), comparison_date: getFormattedDate(getDaysAgo(formObj.comparison_date)) })
        } else {
            setFormObj({...formObj, selected_date: getFormattedDate(getDaysAgo(formObj.selected_date)-2), comparison_date: getFormattedDate(getDaysAgo(formObj.comparison_date)-2) })
        }
    }



    function sumYValues(data) {
        return data.reduce((sum, item) => sum + item.y, 0);
    }



    return (<>


        <FormBox title='Analytics Overview'>

            <Label className='input-grid'>
                <span className={'form-box-detail'}>Selected Date</span>
                <span className={'form-box-detail'}>Comparison Date</span>
                <input type='date' className={'input-date'} name='selected_date' onChange={onChange} value={formObj.selected_date}/>
                <input type='date' className={'input-date'} name='comparison_date' onChange={onChange} value={formObj.comparison_date}/>
            </Label>


            <Label>

                <header className={'width50 pull-left'}>
                    <h2>

                        {formObj.selected_date}

                    </h2>
                </header>

                <div className={'width50 pull-left'}>
                    <button className={'btn btn-paginator pull-right'} name={'date_go_forward'} onClick={updateSelectedDateOnClick}>{'>'}</button>
                    <button className={'btn btn-paginator pull-right'} name={'date_go_back'} onClick={updateSelectedDateOnClick}>{'<'}</button>
                </div>
                <div className={'clear'}></div>
            </Label>




            { requestStatus == RequestStatusEnum.LOADING ? (

                    <ApiMsg requestStatus={requestStatus} responseData={responseData}/>


                ) : (requestStatus == RequestStatusEnum.DONE && responseData.success && responseData.data.data.length>0) &&


                <>

                    <Label>
                        {formatNumber(sumYValues(responseData.data.data[0]))} {responseData.data.title}
                    </Label>

                    <div ref={chartRef} style={{ position: 'relative' }}>

                        <FlexibleXYPlot
                            height={300}
                            style={{ width: '100%' }}
                            onMouseLeave={handleMouseLeave}
                            xType='time'
                        >
                            <YAxis
                                title=""
                                tickFormat={tick => formatNumber(tick)}
                                style={{
                                    // line: { stroke: '#ADDDE1' },
                                    //ticks: { stroke: '#ADDDE1' },
                                    text: { stroke: 'none', fill: '#fff', fontWeight: 600 },
                                }}
                            />



                            {responseData.data.data.map((item, i)=>{
                                return (
                                    <LineSeries
                                        key={i}
                                        data={item}
                                        onNearestX={handleNearestX}

                                        style={{
                                            strokeLinejoin: 'round',
                                            strokeWidth: 2,
                                            stroke: get_chart_color_by_index(i),
                                            fill: 'none',
                                        }}
                                    />
                                )
                            })}




                            {/*<LineSeries
                            data={responseData.data[0]}
                            onNearestX={handleNearestX}

                            style={{
                                strokeLinejoin: 'round',
                                strokeWidth: 2,
                                stroke: '#1F77B4',
                                fill: 'none',
                            }}
                        />*/}



                            {/*<LineSeries
                            data={data[1]}
                            onNearestX={handleNearestX}
                            style={{
                                strokeLinejoin: 'round',
                                strokeWidth: 2,
                                stroke: '#FF7F0E',
                                fill: 'none',
                            }}
                        />*/}



                            <Crosshair
                                titleFormat={(d) => ({title: responseData.data.title, value: convertHourTo12HourFormat(d[0].x)})}
                                itemsFormat={build_item_labels}//[{title: 'data 1', value: d[0].y}, {title: 'data 2', value: d[1].y}]}
                                values={crosshairValues}
                                className={'test-class-name'}
                            />




                            {/*{mousePosition.x !== null && (*/}

                            {/*    <line*/}
                            {/*        x1={mousePosition.absoluteX}*/}
                            {/*        x2={mousePosition.absoluteX}*/}
                            {/*        y1={0}*/}
                            {/*        y2={200}*/}
                            {/*        stroke="red"*/}
                            {/*        strokeWidth={2}*/}
                            {/*        style={{*/}
                            {/*            pointerEvents: 'none',*/}
                            {/*            zIndex: 999, // Ensure the line is on top of other elements,*/}
                            {/*            position:'absolute',*/}
                            {/*            top:0,*/}
                            {/*            width:'5px',*/}
                            {/*            bottom:0*/}
                            {/*        }}*/}
                            {/*    />*/}


                            {/*)}*/}




                            {/*{hoveredPoint && (*/}
                            {/*    <div*/}
                            {/*        className="custom-hint-box"*/}
                            {/*        style={{*/}
                            {/*            position: 'absolute',*/}
                            {/*            left: mousePosition.relativeX + 10,*/}
                            {/*            top: mousePosition.relativeY - 30,*/}
                            {/*            background: '#000',*/}
                            {/*            padding: '10px',*/}
                            {/*            border: '1px solid #ccc',*/}
                            {/*            borderRadius: '5px',*/}
                            {/*            pointerEvents: 'none',*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        <div>{`X: ${hoveredPoint.x}`}</div>*/}
                            {/*        <div>{`${hoveredPoint.series1}: ${hoveredPoint.y1}`}</div>*/}
                            {/*        <div>{`${hoveredPoint.series2}: ${hoveredPoint.y2}`}</div>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </FlexibleXYPlot>

                        {/*<DiscreteColorLegend
                        orientation="horizontal"
                        items={[
                            {
                                title: 'Series 1',
                                color: '#1F77B4',
                                onClick: () => toggleSeries('series1'),
                                disabled: !seriesEnabled.series1
                            },
                            {
                                title: 'Series 2',
                                color: '#FF7F0E',
                                onClick: () => toggleSeries('series2'),
                                disabled: !seriesEnabled.series2
                            }
                        ]}
                    />*/}
                    </div>
                </>

            }







        </FormBox>

        <SideComponent>
            <SideComponentBox >
                <header>
                    <h2>Stats</h2>
                </header>


                {statsRequestStatus == RequestStatusEnum.LOADING ? (

                    <ApiMsg requestStatus={statsRequestStatus} responseData={statsResponseData}/>


                ) : (statsRequestStatus == RequestStatusEnum.DONE &&statsResponseData.success) &&

                    (<article>

                        {statsResponseData.data.map((item, i) => {

                            return (
                                <div key={i} className={'form-box-details'}>
                                    <span className={'form-box-detail form-box-detail-key textalign-left'}>{item.title}</span>
                                    <span className={'form-box-detail form-box-detail-value font40 textalign-right'}>{item.value}</span>
                                </div>
                            )


                        })}


                    </article>)
                }


                {/*<FlexibleXYPlot height={200} style={{width:'100%'}}>
                    <VerticalBarSeries data={data[0]} />
                </FlexibleXYPlot>*/}
            </SideComponentBox>

        </SideComponent>




    </>);
};


export default Overview;