import React, {useState, useEffect, useReducer, createContext, useContext, useMemo} from "react";
import AppContext from "./context/AppContext";
import './style/index.css';
import './style/calender.css';
import Calendar from 'react-calendar';
import Head from './pages/page-sections/Head';
import ScheduleNew from "./pages/ScheduleNew";
import {Button, NavButton} from './pages/components/buttons/';
import {PopupScreen} from "./pages/page-utils";
import helpers from './helpers';
import ReactRouterSetup from "./pages";
import useFetchApi from "./custom-hooks/useFetchApi";


const isProductionBuild = !(!process.env.NODE_ENV || process.env.NODE_ENV === 'development');
const isLocalHost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";



const defaultState = {
    PUBLIC_PATH: window.location.protocol+'//'+window.location.hostname + (window.location.port ? ':'+window.location.port: '') + (!isProductionBuild || isLocalHost ? '/analytics/#/' : '/' ),
    //CDN_CONTENT_PATH: false,
    API: {
        //REMEMBER: If the app is running on the dev server and not a production build, fetch will run requests threw a proxy server set in package.json. proxy = proxy endpoint. This is done to allow cross origin cookies / requests

        'url':(!isProductionBuild || isLocalHost ? '/analytics/api/' : '/api/' )//'http://localhost/analytics/api/',

    },
    profile: {
        loggedIn: false,
    },
    site_data: {},
    /*UI: { //Place inside a different context so that its not routes to be re-evaluated
        popupScreen: {
            show: false,
            content: '',

        },

    },*/
    sideComponent: (<></>)
};


function reducer(state, action){
    //alert(JSON.stringify(state))
    //REMEMBER! If dispatch keeps being triggered, that is because the route is re-evaluated/rendered anytime the AppContext obj is changed, use a different context

    switch (action.type){
        /*

        //Place inside a different context so that its not routes to be re-evaluated

        case 'SHOW_POPUPSCREEN':

            return {...state, UI:{
                        popupScreen:{
                            show: true,
                            content: action.payload
                        }
                    }
                }
            break;
        case 'HIDE_POPUPSCREEN':
            return {...state, UI:{
                    popupScreen:{
                        show: false,
                    }
                }
            }
            break;
*/
         case 'SET_DOM_DATA':
             return {...state, profile: action.payload.profile, site_data: action.payload.site_data}
             break;
        // case 'SET_PROFILE':
        //     return {...state, profile: {...action.payload}}
        //     break;
        // case 'SET_SITE_DATA':
        //     return {...state, site_data: {...action.payload}}
        //     break;
    }
    throw new Error('no matching action type');
}



function App() {



    /*const [popupScreenShow, setPopupScreenShow] = useState(false);
    const [popupScreenContent, setPopupScreenContent] = useState(false);

    const appContextObj = {
        profile: {

        }, UI: {
            popupScreen: {
                show: popupScreenShow,
                setShow: setPopupScreenShow,
                content: '',
                setContent: setPopupScreenContent
            }
        }
    };*/

    //useReducer is a cleaner way of using useState when dealing with many useState's
    const [state, dispatch] = useReducer(reducer, defaultState);


    const contextValue = useMemo(() => {
        return { state, dispatch };
    }, [state, dispatch]);




    //REMEMBER!! If getting infinite re-render, do NOT use AppContext for anything other than the authentication since it will rerender the route anytime it is changed


    //Use memo to prevent inline object on provider, inline objects cause all components subcribed to a value to re-render any time the object is changed
    return (
        <AppContext.Provider value={contextValue}>
            <div className="App">





                <div className='wrap'>

                    <ReactRouterSetup/>
                </div>

            </div>
        </AppContext.Provider>

    );
}

export default App;
