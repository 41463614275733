import React from 'react';

function FormBoxImage(props) {
    const {src, ...otherProps} = props;

    return (<>
        <div className={'form-box-image'}>
            <img src={src}/>
        </div>
    </>);
}


export default FormBoxImage;